import styled, { css } from "styled-components";

export const ImageItem = styled.div<{ $isFirst: boolean; $isActive: boolean }>`
  position: ${({ $isFirst }) => ($isFirst ? "relative" : "absolute")};
  ${({ $isFirst }) =>
    !$isFirst &&
    css`
      width: 100%;
      top: 0;
      left: 0;
    `}
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

export const ArrowButton = styled.div`
  position: relative;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary};
  transition: background-color 0.3s ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.accent};
    }
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
