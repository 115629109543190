import React from "react";
import { Container, Box, Col, Row } from "~/components/Layout";
import { ResponsiveImage } from "~/components/Image";
import { RichText, Text } from "~/components/Text";
import type { ImageComparisonProps } from "./types";

import { useTheme } from "styled-components";
import { useMatchMedia } from "~/helpers/hooks/useMatchMedia";

import DesktopImageComparison from "./DesktopImageComparison";
import MobileImageComparison from "./MobileImageComparison";

const ImageComparison: React.FC<ImageComparisonProps> = ({ captions = [], images }) => {
  const { left, right } = images;

  const { mediaQueries } = useTheme();
  const isNotMobile = useMatchMedia(mediaQueries.md);

  const rightImage = <ResponsiveImage image={right} imgStyle={{ userSelect: "none" }} />;
  const leftImage = (
    <ResponsiveImage
      image={left}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
      }}
      imgStyle={{
        position: "absolute",
        width: isNotMobile ? "auto" : "100%",
        height: isNotMobile ? "100%" : "auto",
        top: "0",
        left: "0",
        userSelect: "none",
        pointerEvents: "none",
      }}
    />
  );

  return (
    <Box position="relative">
      <Container my="5xl">
        {isNotMobile ? (
          <DesktopImageComparison rightImage={rightImage} leftImage={leftImage} />
        ) : (
          <MobileImageComparison rightImage={rightImage} leftImage={leftImage} />
        )}
        <Row mt="4xl">
          {captions.map((caption, i) => {
            return (
              <Col key={i}>
                <Text typeStyle="heading-base" mb="sm">
                  {caption.heading}
                </Text>
                <RichText>{caption.description}</RichText>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Box>
  );
};

export default ImageComparison;
