import styled from "styled-components";
import css from "@styled-system/css";
import { headerSecondaryHeight } from "~/components/Header/constants";
import { ResponsiveImage } from "~/components/Image";
import { Container } from "~/components/Layout";

const headerSafeHeight = `calc(100vh - ${headerSecondaryHeight}px)`;

export const BackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: ${headerSecondaryHeight}px;
  left: 0;
  height: ${headerSafeHeight};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepContainer = styled(Container)<{ $isActive: boolean }>`
  position: absolute;
  transition: opacity 350ms ease-out;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};

  ${css({
    mt: ["7xl", "7xl", 0],
  })()}

  &:first-child {
    margin-top: 0;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${css({
    height: ["auto", "auto", `calc(100vh - ${headerSecondaryHeight}px)`],
    pt: ["5xl", "5xl", 0],
  })()}
`;

export const ItemImageContainer = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

export const GalleryBackgroundImage = styled(ResponsiveImage).attrs({ objectFit: "cover" })`
  position: relative;
  height: 100%;
  width: calc(100% * 4 / 12);

  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, transparent, transparent 60%, ${({ theme }) => theme.colors.background}),
      linear-gradient(
        to bottom,
        ${({ theme }) => theme.colors.background},
        transparent 40%,
        transparent 60%,
        ${({ theme }) => theme.colors.background}
      );
  }
`;
