import styled from "styled-components";
import css from "@styled-system/css";

export const NumberLabel = styled.div`
  position: absolute;
  margin: 0.75rem;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AccordionItem = styled.div<{ $active: boolean }>`
  display: flex;
  position: relative;
  flex: ${({ $active }) => ($active ? 8 : 1)};
  overflow: hidden;
  transition: flex 0.5s ease;
  border-color: ${({ theme }) => theme.colors.secondary};
  border-style: solid;
  border-width: 0;
  cursor: ${({ theme }) => theme.cursors.pointer};

  &:not(:first-child) {
    ${css({
      borderLeftWidth: ["0px", "1px ", "1px "],
      borderTopWidth: ["1px ", "0px", "0px"],
    })()}
  }
`;
