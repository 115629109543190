import React from "react";
import { SectionResolverProps } from "./types";
import { motion, AnimatePresence } from "framer-motion";

/**
 * Sections are the building blocks of chapters and this component resolves the sections of a chapter.
 *
 * See /src/templates/chapter.tsx for more information how props are passed into the resolver.
 *
 * @param {Object} props - Props for SectionResolver
 * @param {Object} props.registry - Dictionary mapping component keys to their respective React components found in the src/components/ folder
 * @param {Object} props.sections - Array of objects pulled from ContentStack containing component keys and props (keys are in the same format as the registry keys)
 */
const SectionResolver: React.FC<SectionResolverProps> = ({ sections, registry }) => (
  <AnimatePresence>
    {sections?.map((section, sectionIndex) => {
      const [sectionKey, sectionData] = Object.entries(section).find(([, value]) => value !== null) || [];
      if (sectionKey && registry.hasOwnProperty(sectionKey)) {
        const Component = registry[sectionKey];
        const componentKey = `${sectionKey}_${sectionIndex}`;

        return (
          <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} key={componentKey} id={componentKey}>
            <Component {...sectionData} />
          </motion.section>
        );
      }

      if (sectionKey) {
        console.warn(`Section key "${sectionKey}" was passed to Section Resolver, but is undefined in the registry.`);
      }
    })}
  </AnimatePresence>
);

export default SectionResolver;
