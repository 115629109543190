import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTheme } from "styled-components";
import { useInView } from "react-hook-inview";
import { useTranslation } from "react-i18next";
import { Box, Container, Flex } from "~/components/Layout";
import { Text, RichText } from "~/components/Text";
import { fontWeightToTranslationKey } from "~/helpers/fontWeightToTranslationKey";
import { useMatchMedia } from "~/helpers/hooks/useMatchMedia";
import { TypefaceWeightsProps } from "./types";
import {
  LiveTextContainer,
  ConfigurationDropdown,
  ConfigurationHeader,
  CaretContainer,
  ConfigurationDropdownContainer,
} from "./styled";
import { AnimatePresence, motion } from "framer-motion";

const Caret = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.02344L5 5.02344L9 1.02344" />
    </svg>
  );
};

const TypefaceWeights: React.FC<TypefaceWeightsProps> = ({
  font,
  font_size,
  configurations,
  caption,
  placeholder,
  disclaimer,
}) => {
  const { t } = useTranslation();
  const [inViewRef, inView] = useInView();
  const [configIndex, setConfigIndex] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [placeholderString, setPlaceholderString] = useState("");
  const [blinkCursor, setBlinkCursor] = useState(false);
  const placeholderRef = useRef<HTMLSpanElement | null>(null);
  const timeoutRef = useRef<number | null>(null);
  const [fontFamily] = font;
  const config = configurations[configIndex];
  const theme = useTheme();
  const isNotMobile = useMatchMedia(theme.mediaQueries.md);

  const fontStyles = configurations.map((config) => {
    return t(fontWeightToTranslationKey(config.weight)) + (!!config.italic ? ` ${t("font_styles_italic")}` : "");
  });

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setConfigIndex(parseInt(event.target.value, 10));
  };

  const onPaste = (event: React.ClipboardEvent<HTMLElement>) => {
    event.preventDefault();

    const text = event?.clipboardData.getData("text/plain") || "";

    if (document.queryCommandSupported("insertText")) {
      document.execCommand("insertText", false, text);
    } else {
      document.execCommand("paste", false, text);
    }
  };

  const animateStep = useCallback(() => {
    if (placeholderRef.current && placeholder) {
      setPlaceholderString((prevPlaceholderString) => {
        let newLength = prevPlaceholderString.length + 1;
        while (placeholder[newLength - 1] === " " && newLength < placeholder.length) {
          newLength += 1;
        }
        if (prevPlaceholderString.length < placeholder.length) {
          setBlinkCursor(false);
          timeoutRef.current = window.setTimeout(animateStep, 200 * (0.5 + 0.5 * Math.random()));
        } else setBlinkCursor(true);
        return placeholder.slice(0, newLength);
      });
    }
  }, [placeholder]);

  const headerText = isNotMobile
    ? `${config.name} • ${fontFamily.title} ${fontStyles[configIndex]} ${
        !!config.italic ? t("font_styles_italic") : ""
      }`
    : fontFamily.title;

  useEffect(() => {
    if (inView && !isFocused) animateStep();
    else if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
      setPlaceholderString("");
    }
  }, [animateStep, inView, isFocused]);

  return (
    <Container py={["xl", "5xl"]} ref={inViewRef}>
      <Box borderTop="1px solid" borderColor="nav.border">
        <Flex justifyContent="space-between" alignItems="center" mt="base">
          <ConfigurationHeader>{headerText}</ConfigurationHeader>
          <ConfigurationDropdownContainer>
            <ConfigurationDropdown as="select" value={configIndex} onChange={onChange}>
              {fontStyles.map((fontStyle, i) => {
                return (
                  <option key={i} value={String(i)}>
                    {fontStyle}
                  </option>
                );
              })}
            </ConfigurationDropdown>
            <CaretContainer>
              <Caret />
            </CaretContainer>
          </ConfigurationDropdownContainer>
        </Flex>

        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`${fontFamily.title}_${config.weight}_${config.italic ? "italic" : "normal"}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LiveTextContainer
              $fontFamily={fontFamily.title}
              $fontStyle={config.italic ? "italic" : "normal"}
              $fontWeight={config.weight}
              $fontSize={font_size}
              $blinkCursor={blinkCursor}
              suppressContentEditableWarning={true}
              contentEditable={true}
              onPaste={onPaste}
              onFocus={() => setIsFocused(true)}
            >
              <Box as="span" opacity={isFocused ? 1 : 0}>
                {placeholder}
              </Box>
              {!isFocused && (
                <span ref={placeholderRef} aria-hidden className="placeholder">
                  {placeholderString}
                </span>
              )}
            </LiveTextContainer>
          </motion.div>
        </AnimatePresence>

        <Box width={["100%", "100%", "50%"]}>
          <Text mt="7xl" typeStyle="heading-sm" textColor="secondary">
            {caption?.heading}
          </Text>
          <RichText mt="xs">{caption?.description}</RichText>
        </Box>

        <Text typeStyle="body-xs" mt="lg" textColor="body">
          {disclaimer}
        </Text>
      </Box>
    </Container>
  );
};

export default TypefaceWeights;
