import React from "react";
import { Box } from "~/components/Layout";
import { Text, RichText } from "~/components/Text";
import { ResponsiveImage } from "~/components/Image";
import DownloadButton from "~/components/DownloadButton";
import RevealText from "~/components/RevealText";
import type { MultiImageItemProps } from "./types";
import { useParallax } from "~/helpers/hooks/useParallax";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";

/**
 * Section with multiple images, each with an optional caption block.
 */
const MultiImageItem: React.FC<MultiImageItemProps> = ({ image, caption: [caption], enable_parallax, index }) => {
  const [inViewAnimationRef, inView] = useInViewAnimation();
  const parallaxRef = useParallax(inView && enable_parallax);
  const download = caption?.download?.[0];

  return (
    <div ref={inViewAnimationRef}>
      <Box position="relative" ref={parallaxRef} className="animate-inview-scale">
        <ResponsiveImage image={image} />
      </Box>
      {caption && (
        <Box mt="3xl">
          {caption.icon?.url && <Box as="img" src={caption.icon?.url} mb="lg" />}
          <Text typeStyle="heading-sm" mb="xs" textColor="secondary">
            {caption.label}
          </Text>
          <RevealText delay={index * 300} typeStyle={["heading-base", "heading-xl"]} textColor="primary" mb="xs">
            {caption.heading}
          </RevealText>
          <RichText typeStyles={{ p: "body-sm" }}>{caption.description}</RichText>
          {!!download?.file && (
            <Box mt="lg">
              <DownloadButton asset={download.file} label={download.label} />
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default MultiImageItem;
