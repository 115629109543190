import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Container, Row, Col } from "~/components/Layout";
import { Text } from "~/components/Text";
import { ResponsiveAsset } from "~/components/Asset";
import DownloadButton from "~/components/DownloadButton";
import DownloadLinks from "~/components/DownloadLinks";
import RevealText from "~/components/RevealText";
import type { DownloadSectionProps } from "./types";

const DownloadSection: React.FC<DownloadSectionProps> = ({ label, heading, download, media }) => {
  const { t } = useTranslation();

  return (
    <Container py={["4xl", "7xl"]} maxWidth="100vw" overflow="hidden">
      <Row alignItems="center" justifyContent="center">
        <Col order={[1, 1, 0]} span={media ? [12, 12, 8, 7] : [12, 8]}>
          <Flex
            flexDirection={["column", "row"]}
            justifyContent={[null, "space-between"]}
            alignItems={[null, "flex-end"]}
          >
            <Box mb={["xl", 0]}>
              <Text as="div" typeStyle="heading-xs" textColor="secondary">
                {label}
              </Text>
              <RevealText as="h2" typeStyle="heading-base" textColor="primary" mt="base">
                {heading}
              </RevealText>
            </Box>
            {!!download.package_download && (
              <DownloadButton asset={download.package_download} label={t("download_package")} />
            )}
          </Flex>
          <Box mt={["xl", "5xl"]}>
            <DownloadLinks assets={download.individual_downloads} />
          </Box>
        </Col>
        {media && (
          <Col order={[0, 0, 1]}>
            <Flex alignItems="center" justifyContent="center">
              <ResponsiveAsset asset={media} />
            </Flex>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default DownloadSection;
