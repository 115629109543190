import { parseToRgb } from "polished";
import React from "react";
import { useTheme } from "styled-components";
import { Container, Row, Box, Col, Flex } from "~/components/Layout";
import { RichText, Text } from "~/components/Text";
import { hasEnoughContrast } from "~/helpers/color";
import { ColorsSectionProps } from "./types";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";

const ColorsSection: React.FC<ColorsSectionProps> = ({ caption, colors, show_color_details }) => {
  const { heading, subheading, description } = caption;
  const theme = useTheme();
  const [animateChildren] = useInViewAnimation();

  const minColSpan = 12 / Math.min(3, colors.length);

  return (
    <Container py={["xl", "4xl"]}>
      <Text typeStyle="heading-base" textColor="primary">
        {heading}
      </Text>

      <Text typeStyle="heading-sm" textColor="secondary" mt="xs">
        {subheading}
      </Text>
      <RichText mt="xs">{description}</RichText>

      <Row pt={[0, "3xl"]} ref={animateChildren}>
        {colors.map((data, colorIndex) => {
          const { hex_code, cmyk, pantone_color, title } = data;
          const rgb = parseToRgb(hex_code);
          const borderColor = hasEnoughContrast(hex_code, theme?.colors?.background)
            ? hex_code
            : theme.colors.utility.secondary;

          return (
            <Col key={colorIndex} span={[12, 12, minColSpan]} mt={["4xl", "4xl", "3xl"]}>
              <Box height="5rem" border="1px solid" borderColor={borderColor} bg={hex_code} />

              {show_color_details && (
                <Box>
                  <Flex my="sm">
                    <Text typeStyle="heading-xs" textColor="secondary">
                      {title}
                    </Text>
                  </Flex>

                  <Flex justifyContent="space-between" color="body">
                    <Text typeStyle="body-xs">{hex_code?.toUpperCase()}</Text>
                    <Text typeStyle="body-xs">{`RGB: ${rgb.red}, ${rgb.green}, ${rgb.blue}`}</Text>
                    {!!cmyk && <Text typeStyle="body-xs">{`CMYK: ${cmyk}`}</Text>}
                    {!!pantone_color && <Text typeStyle="body-xs" textColor="body">{`PMS: ${pantone_color}`}</Text>}
                  </Flex>
                </Box>
              )}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ColorsSection;
