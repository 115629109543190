import React, { useMemo, useRef, useCallback, useState } from "react";
import { useInView } from "react-hook-inview";
import { Box } from "~/components/Layout";
import { RichText, Text } from "~/components/Text";
import { ResponsiveAsset } from "~/components/Asset/index";
import RevealText from "~/components/RevealText";
import type { Asset } from "~/types/asset";
import ScrollingGalleryBackground from "./ScrollingGalleryBackground";
import ScrollingGalleryItem from "./ScrollingGalleryItem";
import type { ScrollingGalleryProps } from "./types";
import { useScrollEventListener } from "~/helpers/hooks/useScrollEventListener";
import { clamp } from "~/helpers/clamp";
import { StickyContainer } from "./styled";
import { useTheme } from "styled-components";
import { useMatchMedia } from "~/helpers/hooks/useMatchMedia";

const ScrollingGallery: React.FC<ScrollingGalleryProps> = ({ intro: [intro] = [], items, background_image }) => {
  const { mediaQueries } = useTheme();
  const isNotMobile = !!useMatchMedia(mediaQueries.md);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const stickyContainerRef = useRef<HTMLDivElement | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [inViewRef, inView] = useInView();

  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      inViewRef(node);
      containerRef.current = node;
    },
    [inViewRef],
  );

  const responsiveImages = useMemo(() => {
    const mediaItems: Asset[][] = [];
    if (intro) mediaItems.push(intro.media);
    items.forEach(({ media }) => mediaItems.push(media));
    return mediaItems.map((itemImage, itemImageIndex) => (
      <ResponsiveAsset
        style={{ width: "100%", height: "100%" }}
        imageProps={{ objectFit: "contain" }}
        key={`scrolling-gallery-media--${itemImageIndex}`}
        asset={itemImage}
      />
    ));
  }, [intro, items]);

  const [introResponsiveImage, ...itemResponsiveImages] = responsiveImages;

  const [length, step] = useMemo((): number[] => [(!!intro ? 1 : 0) + items.length, 1 / items.length], [
    intro,
    items.length,
  ]);

  const handleScroll = useCallback(
    ({ scrollProgress }: { scrollTop: number; scrollProgress: number }) => {
      const interpolatedScrollProgress = -(Math.cos(Math.PI * scrollProgress) - 1) / 2; // easeInOutSine
      const progress = interpolatedScrollProgress / step;
      const newIndex = clamp(Math.floor(progress), 0, length - 1);
      setActiveIndex(() => {
        if (isNotMobile && stickyContainerRef && stickyContainerRef.current) {
          const localProgress = progress % 1;

          const node = stickyContainerRef.current.children[newIndex] as HTMLElement;
          if (node) {
            node.style.transform = `translateY(${(localProgress - 0.5) * -10}vh)`;
          }
        }
        return newIndex;
      });
    },
    [length, step, isNotMobile],
  );

  useScrollEventListener(handleScroll, inView, containerRef);

  return (
    <Box ref={setRefs} position="relative" my="4xl" height={`${length * 100}vh`}>
      <ScrollingGalleryBackground
        background_image={background_image}
        activeIndex={activeIndex}
        responsiveImages={responsiveImages}
        isNotMobile={isNotMobile}
      />
      <StickyContainer ref={stickyContainerRef}>
        {!!intro && (
          <ScrollingGalleryItem
            isNotMobile={isNotMobile}
            responsiveImage={introResponsiveImage}
            isActive={activeIndex === 0}
          >
            <RevealText as="h3" typeStyle="heading-xl" mb="base">
              {intro.caption.heading}
            </RevealText>
            <RichText>{intro.caption.description}</RichText>
          </ScrollingGalleryItem>
        )}
        {items.map((item, itemIndex) => (
          <ScrollingGalleryItem
            key={`scrolling-gallery-item--${itemIndex}`}
            responsiveImage={itemResponsiveImages[itemIndex]}
            isActive={itemIndex + (!!intro ? 1 : 0) === activeIndex}
            isNotMobile={isNotMobile}
          >
            {!!item.caption.icon && (
              <Box as="img" width="1.875rem" height="1.875rem" mb="2xl" src={item.caption.icon.url} />
            )}
            <Text as="div" typeStyle="heading-xs" mb="xs" textColor="border.secondary">
              {item.caption.label}
            </Text>
            <Text as="h5" typeStyle="heading-sm" mb="xs">
              {item.caption.heading}
            </Text>
            <RichText typeStyles={{ p: "body-sm" }}>{item.caption.description}</RichText>
          </ScrollingGalleryItem>
        ))}
      </StickyContainer>
    </Box>
  );
};

export default ScrollingGallery;
