import React from "react";
import { Container, Col, Row } from "~/components/Layout";
import { ImageSpecSectionProps } from "./types";
import ImageSpecItem from "./ImageSpecItem";

const ImageSpecSection: React.FC<ImageSpecSectionProps> = ({ items }) => {
  const itemColumnSpan = 12 / Math.min(2, items.length);
  return (
    <Container pb="7xl">
      <Row>
        {items.map(
          (item, itemIndex) =>
            item.main_image && (
              <Col span={[12, 12, itemColumnSpan]} key={`image-spec-section--${item.title}-${itemIndex}`} mt="4xl">
                <ImageSpecItem {...item} />
              </Col>
            ),
        )}
      </Row>
    </Container>
  );
};

export default ImageSpecSection;
