import React from "react";
// import { useTheme } from "styled-components";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Container, Grid, Flex, Row, Col } from "~/components/Layout";
import { Text, RichText } from "~/components/Text";
import { DEFAULT_LOCALE } from "~/helpers/rioti18n";
import { LETTERS, SYMBOLS } from "./constants";
import type { TypefaceShowcaseProps } from "./types";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";
import RevealText from "../RevealText";

/**
 * Display font samples in a grid.
 */
const TypefaceShowcase: React.FC<TypefaceShowcaseProps> = ({ fonts: [font] }) => {
  const { language } = useI18next();
  // const { supportsUppercasing } = useTheme();
  const [animateChildren] = useInViewAnimation({ delay: 20 });

  // function upperChar(char: string): string {
  //   return supportsUppercasing ? char.toLocaleUpperCase(language) : "";
  // }

  const letters = LETTERS[language] || LETTERS[DEFAULT_LOCALE];
  const symbols = SYMBOLS[language] || SYMBOLS[DEFAULT_LOCALE];

  // Create an array of upper and lowercase letters and symbols for the grid.
  // For languages that do not support uppercase, this will leave the first item
  // blank and render a single character, i.e. "" + "あ".
  const characters = [
    // ["Aa", "Bb", ...]
    ...Array.from(letters, (char) => char.toLocaleUpperCase(language) + char),
    // [..., "?", "!"]
    ...Array.from(symbols),
  ];

  const [showcaseChar] = characters;
  const [fontFamily] = font.font;

  return (
    <section>
      <Container py={["xl", "7xl"]}>
        <Row>
          <Col span={[12, 12, 5, 3]}>
            {/* Big showcase letter. Font size is fixed. */}
            <Flex
              fontFamily={fontFamily.title}
              color="secondary"
              fontSize={230}
              mb={["none", "5xl"]}
              alignItems="center"
              height={["auto", 192]}
              lineHeight={1}
            >
              <RevealText>{showcaseChar}</RevealText>
            </Flex>

            {/* Font Title */}
            <Text as="h2" typeStyle="heading-sm" textColor="primary">
              {fontFamily.title}
            </Text>

            {/* Font Description */}
            <RichText mt="sm" mb="xl">
              {font.description}
            </RichText>
          </Col>
          <Col>
            <Grid
              ref={animateChildren}
              gridTemplateColumns="repeat(auto-fill, minmax(5.625rem, 1fr))"
              gridColumnGap="0"
              borderTop="1px solid"
              borderLeft="1px solid"
              borderColor="secondary"
            >
              {characters.map((charString) => {
                return (
                  <Flex
                    key={charString}
                    height="5.625rem"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="background"
                    borderRight="1px solid"
                    borderBottom="1px solid"
                    borderColor="secondary"
                  >
                    {/* Font size is fixed for grid items */}
                    <Text fontFamily={fontFamily.title} fontSize="2rem" textColor="foreground">
                      {charString}
                    </Text>
                  </Flex>
                );
              })}
            </Grid>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TypefaceShowcase;
