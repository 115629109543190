import React, { useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Text, RichText } from "~/components/Text";
import { Box, Flex } from "~/components/Layout";
import DownloadButton from "~/components/DownloadButton";
import { CarouselItemsProps } from "./types";
import { ProgressBar } from "./styled";

const itemVariants = {
  hidden: {
    opacity: "0",
  },
  visible: {
    opacity: "1",
  },
};

const MobileCarouselItems: React.FC<CarouselItemsProps> = ({
  inView,
  items,
  activeItemIndex,
  handleItemClick,
  item_duration,
}) => {
  const progressBarWidth = `${100 / items.length}%`;

  const itemElements = useMemo(
    () =>
      items.map(({ caption: { icon, heading, description, download: [download] = [] } }, itemIndex) => (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={itemVariants}
          key={`single-image-section-caption--${heading}-${itemIndex}`}
        >
          <div onClick={() => handleItemClick(itemIndex + 1)}>
            {!!icon && <Box as="img" mb="sm" width="2.25rem" height="2.25rem" src={icon?.url} />}
            <Text as="h5" typeStyle="heading-base">
              {heading}
            </Text>
            <RichText mt="xs">{description}</RichText>
          </div>
          {!!download?.file && (
            <Box mt="lg">
              <DownloadButton asset={download.file} label={download.label} />
            </Box>
          )}
        </motion.div>
      )),
    [handleItemClick, items],
  );

  return (
    <>
      <Flex mx="-0.25rem">
        {itemElements.map((itemElement, itemIndex) => (
          <Box
            key={itemElement.key}
            px="0.25rem"
            width={progressBarWidth}
            onClick={itemIndex !== activeItemIndex ? () => handleItemClick(itemIndex) : undefined}
          >
            <ProgressBar $isActive={itemIndex === activeItemIndex && inView} $itemDuration={item_duration} />
          </Box>
        ))}
      </Flex>
      <AnimatePresence>{itemElements[activeItemIndex]}</AnimatePresence>
    </>
  );
};

export default MobileCarouselItems;
