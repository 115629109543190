import styled, { keyframes } from "styled-components";

const animChar = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const AnimToken = styled.span<{ $isInView: boolean; $index: number; $delay: number; $interval: number }>`
  overflow: hidden;
  display: inline-block;
  & > span {
    animation: ${animChar} 0.5s ${({ $index, $delay, $interval }) => $index * $interval + $delay}ms var(--ease-bezier);
    animation-fill-mode: both;
    display: inline-block;
    animation-play-state: ${({ $isInView }) => ($isInView ? "running" : "paused")};
  }
`;

export const WordWrapper = styled.span`
  white-space: nowrap;
`;
