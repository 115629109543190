import React from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/Button";
import { useAssetDownload } from "~/helpers/hooks/useAssetDownload";
import type { Asset } from "~/types/asset";

const DownloadButton: React.FC<{ asset: Asset; label?: string }> = ({ asset, label }) => {
  const [{ handleDownload, progress }, isFetching] = useAssetDownload(asset);
  const { t } = useTranslation();
  const buttonLabel = label || t("download");

  return (
    <Button
      onClick={handleDownload}
      icon={progress === 1 ? "check" : isFetching ? undefined : "download"}
      disabled={isFetching}
    >
      {isFetching ? `${t("download_fetching")} ${(progress * 100).toFixed()}%` : buttonLabel}
    </Button>
  );
};

export default DownloadButton;
