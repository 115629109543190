import React from "react";
import { Box, Col } from "~/components/Layout";
import type { ScrollingGalleryItemProps } from "./types";
import { ItemContainer, StepContainer } from "./styled";

const ScrollingGalleryItem: React.FC<ScrollingGalleryItemProps> = ({
  children,
  responsiveImage,
  isActive,
  isNotMobile,
}) => {
  return (
    <StepContainer $isActive={isActive}>
      {!isNotMobile && (
        <Col height="100%" span={12} mb="5xl">
          <Box height="100%" width="100%" position="relative">
            {responsiveImage}
          </Box>
        </Col>
      )}
      <Col span={[12, 12, 4]}>
        <ItemContainer>{children}</ItemContainer>
      </Col>
    </StepContainer>
  );
};

export default ScrollingGalleryItem;
