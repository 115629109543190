import styled from "styled-components";

export const TextSample = styled.div<{
  $fontFamily: string;
  $fontWeight: string;
  $fontStyle: string;
  $lineHeight: number;
  $letterSpacing: number;
  $fontSize: number;
}>`
  margin-top: 1.75rem;
  line-height: ${({ $lineHeight }) => $lineHeight}pt;
  letter-spacing: ${({ $letterSpacing }) => $letterSpacing / 1000}em;
  font-size: ${({ $fontSize }) => $fontSize}pt;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ $fontFamily }) => $fontFamily};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-style: ${({ $fontStyle }) => $fontStyle};
`;

export const TypestyleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
  text-transform: uppercase;
  font-size: 0.625rem;
`;
