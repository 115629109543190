import React from "react";
import { useState, useEffect, useRef, forwardRef, useCallback, Ref } from "react";
import { Image, ImageBox } from "./ResponsiveImage.styled";
import { ResponsiveImageProps, SourceSet } from "./ResponsiveImage.types";
import { useTheme } from "styled-components";

export const ResponsiveImage = forwardRef(
  (
    {
      sources,
      src,
      alt,
      title,
      nativeLazy = false,
      lazy = true,
      lazyTimeout,
      rootMargin = "500px",
      onLoad,
      isBackground = false,
      backgroundFit = "cover",
      backgroundPosition = "center",
      ...rest
    }: ResponsiveImageProps,
    ref: Ref<HTMLPictureElement>,
  ) => {
    const { mediaQueries } = useTheme();
    const [canLoad, setCanLoad] = useState(!lazy);
    const [loaded, setLoaded] = useState(!lazy);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);

    let sourceSet: SourceSet;
    if (sources && sources.length > 0) sourceSet = sources;
    else if (src) sourceSet = [{ isDefault: true, src }];
    else throw new Error("No source provided to ResponsiveImage");

    const defaultMedia =
      sourceSet.find((source) => source.isDefault && !!source.src) || sourceSet.find((source) => !!source.src);

    const otherMedia = sourceSet.filter((source) => !!source.src);

    const altString = alt || title || "";

    const handleLoad = useCallback(() => {
      if (!loaded && imageRef.current) {
        if (onLoad) onLoad();
        setLoaded(true);
        imageRef.current.removeEventListener("load", handleLoad);
      }
    }, [loaded, onLoad]);

    useEffect(() => {
      if (canLoad && !loaded && imageRef.current) {
        const imageLoaded = !!((imageRef.current.complete || imageRef.current.naturalWidth) && imageRef.current.src);
        if (imageLoaded) handleLoad();
        else imageRef.current.addEventListener("load", handleLoad);
      }
    }, [canLoad, handleLoad, loaded]);

    useEffect(() => {
      let loadTimeout: number;
      if (typeof lazyTimeout === "number") loadTimeout = window.setTimeout(() => setCanLoad(true), lazyTimeout);

      if (lazy) {
        const interactionHandler: IntersectionObserverCallback = (entries) => {
          if (entries[0].isIntersecting && observerRef.current) {
            setCanLoad(true);
            observerRef.current.disconnect();
            observerRef.current = null;
          }
        };
        observerRef.current = new IntersectionObserver(interactionHandler, { rootMargin });
        if (imageRef.current) observerRef.current.observe(imageRef.current);
      }

      return () => {
        if (observerRef.current) observerRef.current.disconnect();
        clearTimeout(loadTimeout);
      };
    }, [lazy, lazyTimeout, rootMargin]);

    return (
      <ImageBox
        ref={ref}
        as="picture"
        $isBackground={isBackground}
        $backgroundFit={backgroundFit}
        $backgroundPosition={backgroundPosition}
        $isVisible={loaded}
        {...rest}
      >
        {canLoad && defaultMedia ? (
          <>
            {otherMedia.map(
              (media) =>
                (media.breakpoint || media.width) &&
                !media.isDefault && (
                  <source
                    key={`image-source-${String(media.breakpoint || media.width)}`}
                    media={
                      media.breakpoint
                        ? (mediaQueries[(media.breakpoint as unknown) as number] as string)
                        : `(min-width: ${media.width})`
                    }
                    srcSet={media.src}
                  />
                ),
            )}
            <Image
              ref={imageRef}
              src={defaultMedia.src}
              alt={altString}
              title={title}
              loading={nativeLazy ? "lazy" : "eager"}
            />
          </>
        ) : (
          <Image alt={altString} title={title} ref={imageRef} />
        )}
      </ImageBox>
    );
  },
);

ResponsiveImage.displayName = "ResponsiveImage";
