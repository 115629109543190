import React from "react";
import { Image, ResponsiveImage } from "~/components/Image";
import { Video, ResponsiveVideo } from "~/components/Video";
import type { Asset as AssetType } from "~/types/asset";
import type { ContentstackImageProps } from "~/components/Image/types";
import { isImage, isVideo } from "./helpers";

interface AssetSharedProps {
  style?: React.CSSProperties;
  imageProps?: ContentstackImageProps;
}

interface AssetProps extends AssetSharedProps {
  asset: AssetType;
}

interface ResponsiveAssetProps extends AssetSharedProps {
  asset: AssetType | AssetType[];
}

/**
 * Returns an image or video asset, depending on the `content_type`.
 */
export const Asset: React.FC<AssetProps> = ({ asset, style, imageProps }) => {
  if (Array.isArray(asset)) {
    throw new Error("An array was passed to the `Asset` component. Did you mean to use ResponsiveAsset?");
  }

  if (isVideo(asset)) {
    const mergedStyle = {
      objectFit: imageProps?.objectFit,
      objectPosition: imageProps?.objectPosition,
      ...style,
    };
    return <Video video={asset} style={mergedStyle} />;
  }

  return <Image image={asset} style={style} {...imageProps} />;
};

/**
 * Returns an responsive image or video asset, depending on the `content_type`.
 */
export const ResponsiveAsset: React.FC<ResponsiveAssetProps> = ({ asset, style, imageProps }) => {
  const assets = Array.isArray(asset) ? asset : [asset];

  if (assets.length === 1) {
    return <Asset asset={assets[0]} style={style} imageProps={imageProps} />;
  }

  if (assets.every(isVideo)) {
    const mergedStyle = {
      objectFit: imageProps?.objectFit,
      objectPosition: imageProps?.objectPosition,
      ...style,
    };
    return <ResponsiveVideo video={assets} style={mergedStyle} />;
  }

  if (assets.every(isImage)) {
    return <ResponsiveImage image={assets} style={style} {...imageProps} />;
  }

  throw new Error(
    "A ResponsiveAsset component contains mismatched content types. Make sure all items in Contentstack are of the same type, image (jpg, png, webp) or video (mp4, webm)",
  );
};
