import styled, { css, keyframes } from "styled-components";
import { Box } from "~/components/Layout";
import { Text } from "~/components/Text";

import { getChapterHeroBackgroundGradient } from "./helpers";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ChapterLabel = styled(Text).attrs({ forwardedAs: "div" })`
  animation: ${fadeIn} 1s ease;
  animation-fill-mode: both;
`;

export const BackgroundImageWrapper = styled(Box)`
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 1px;
  left: 0;
  transition: opacity 0.5s ease;
  overflow: hidden;
`;

export const BackgroundGradient = styled(Box)<{ $isExtendedIntro: boolean }>`
  position: relative;
  overflow: hidden;
  background-image: ${({ theme, $isExtendedIntro }) => {
    if ($isExtendedIntro) {
      return css`linear-gradient(${theme.colors.background}, transparent 50%, transparent 70%, ${theme.colors.background})`;
    }
    return getChapterHeroBackgroundGradient(theme);
  }};
`;
