import styled from "styled-components";

export const ArrowContainer = styled.div`
  display: flex;
  height: 3rem;
  width: 3rem;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  align-items: center;

  justify-content: center;
`;

export const ImagesContainer = styled.div`
  position: relative;
  cursor: ${({ theme }) => theme.cursors.drag};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
`;

export const Divider = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  align-items: center;
  width: 3rem;
  top: 0;
  transform: translateX(-50%);
  z-index: 2;
`;

export const VerticalLine = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 1px;
  top: 0;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const LeftImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  overflow: hidden;
`;
