import { Box } from "~/components/Layout";
import styled, { css } from "styled-components";
import cssSystem, { ResponsiveStyleValue } from "@styled-system/css";
import { Property } from "csstype";

export const ImageBox = styled(Box)<{
  $isVisible: boolean;
  $isBackground: boolean;
  $backgroundFit: ResponsiveStyleValue<Property.ObjectFit | undefined> | Property.ObjectFit;
  $backgroundPosition: ResponsiveStyleValue<Property.ObjectPosition | undefined> | Property.ObjectPosition;
}>`
  transition: opacity 0.75s ease;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  ${({ $isBackground, $backgroundFit, $backgroundPosition }) =>
    $isBackground &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        ${cssSystem({ objectFit: $backgroundFit, objectPosition: $backgroundPosition })()};
      }
    `}
`;

export const Image = styled.img`
  display: block;
  height: inherit;
  width: inherit;
  border: inherit;
  border-radius: inherit;
`;
