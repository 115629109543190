import React, { useState, useEffect, useMemo } from "react";
import { useTheme } from "styled-components";
import { Container, Flex, Row, Col } from "~/components/Layout";
import type { BoxProps } from "~/components/Layout/types";
import { Text, RichText } from "~/components/Text";
import { Rule } from "~/components/Decorators";
import RevealText from "~/components/RevealText/index";
import { headerSafeHeight } from "~/components/Header/constants";
import { useBodyLock } from "~/helpers/hooks/useBodyLock";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";
import type { ChapterHeroProps } from "./types";
import { ChapterLabel } from "./styled";
import BackgroundWrapper from "./BackgroundWrapper";

/**
 * Main entry point for each chapter. There are two main variants of this
 * component: The default with the chapter index label and title, and an
 * "extended" version with more text fields for more descriptive introductions.
 *
 * This component is also used as the "next chapter" bumper, set via `asNextChapter`
 * boolean prop. In this case the extended section is always hidden.
 */
const ChapterHero: React.FC<ChapterHeroProps> = ({
  title,
  extended_intro,
  background_image,
  background_video,
  enable_parallax,
}) => {
  const theme = useTheme();
  const [lockScroll, setLockScroll] = useState(true);
  const extendedIntro = extended_intro && extended_intro[0];
  const hasExtendedIntro = !!extendedIntro;
  const [animateChildren] = useInViewAnimation();

  useBodyLock(lockScroll);

  // Side-effect for "next chapter" bumper. Some styles change during
  // transition, and a state update is required.
  useEffect(() => {
    const timeout = window.setTimeout(() => setLockScroll(false), 700);
    return () => window.clearTimeout(timeout);
  }, []);

  // Memoize responsive background wrapper. This avoids re-rendering the
  // background image when state updates, which will cause animations to stutter.
  const [MainWrapper, ExtendedWrapper] = useMemo<React.FC<BoxProps>[]>(() => {
    const BgWrapper: React.FC<BoxProps> = ({ children, ...rest }) => {
      return (
        <BackgroundWrapper
          background_image={background_image}
          background_video={background_video}
          hasExtendedIntro={hasExtendedIntro}
          enable_parallax={enable_parallax}
          {...rest}
        >
          {children}
        </BackgroundWrapper>
      );
    };

    return [hasExtendedIntro ? React.Fragment : BgWrapper, hasExtendedIntro ? BgWrapper : React.Fragment];
  }, [background_image, background_video, enable_parallax, hasExtendedIntro]);

  return (
    <section>
      <MainWrapper>
        <Container
          py={hasExtendedIntro ? "0" : ["8xl", "9xl"]}
          height={[`calc(${headerSafeHeight} * 0.9)`, headerSafeHeight]}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          maxWidth="100vw"
          overflow="hidden"
        >
          <Flex flexDirection="column" alignItems="center">
            <ChapterLabel typeStyle="heading-sm" mb="base" textColor="secondary">
              {title?.label}
            </ChapterLabel>
            <RevealText
              type="letter"
              as="h1"
              textColor="primary"
              typeStyle={["heading-2xl", "heading-3xl", "heading-5xl"]}
            >
              {title.heading}
            </RevealText>
          </Flex>
          <Row mt="2xl">
            <Col span={[12, 10, 6, 6]} offset={[0, 1, 3, 3]}>
              <Rule size="large" />
            </Col>
          </Row>
        </Container>
      </MainWrapper>
      {hasExtendedIntro && (
        <ExtendedWrapper
          mt={[`-${theme.space["8xl"]}`, `-${theme.space["9xl"]}`]}
          pt={["10xl", "11xl"]}
          pb={["9xl", "10xl"]}
        >
          <Container>
            <Row>
              <Col span={[12, 6]}>
                <Text as="div" typeStyle="heading-sm" textColor="secondary">
                  {extendedIntro?.label}
                </Text>
                <RevealText typeStyle={["heading-xl", "heading-4xl"]} as="h2" mt="lg" textColor="primary">
                  {extendedIntro?.heading}
                </RevealText>
              </Col>
            </Row>
            <Row justifyContent="flex-end" mt="9xl">
              <Col span={[12, 4]} ref={animateChildren}>
                <Text as="h3" typeStyle={["heading-lg", "heading-xl"]}>
                  {extendedIntro?.subheading}
                </Text>
                <RichText mt="lg">{extendedIntro?.description}</RichText>
              </Col>
            </Row>
          </Container>
        </ExtendedWrapper>
      )}
    </section>
  );
};

export default ChapterHero;
