import React from "react";
import { Box, Container, Row, Col } from "~/components/Layout";
import { ResponsiveImage } from "~/components/Image";
import { Text, RichText } from "~/components/Text";
import RevealText from "~/components/RevealText";
import DownloadButton from "~/components/DownloadButton";
import { useParallax } from "~/helpers/hooks/useParallax";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";
import type { SingleImageSectionProps } from "./types";
import ZoomImage from "./ZoomImage";

const SingleImageSection: React.FC<SingleImageSectionProps> = ({
  main_image: image,
  full_bleed,
  captions,
  enable_zoom,
  zoom_amount,
  enable_parallax,
}) => {
  const parallaxRef = useParallax(!enable_zoom && enable_parallax, 0.2);
  const captionColumnWidth = 12 / Math.min(4, captions.length);
  const ImageContainer = full_bleed ? Box : Container;
  const [scaleUpRef] = useInViewAnimation();

  return (
    <Box py={["xl", "5xl"]} ref={enable_zoom ? () => null : scaleUpRef}>
      <ImageContainer>
        <Box ref={parallaxRef} className="animate-inview-scale" position="relative">
          {enable_zoom ? <ZoomImage zoom_amount={zoom_amount} main_image={image} /> : <ResponsiveImage image={image} />}
        </Box>
      </ImageContainer>
      <Container mt="3xl">
        <Row>
          {captions.map(({ icon, label, heading, description, download: [download] = [] }, captionIndex) => (
            <Col
              key={`single-image-section-caption--${label || heading}-${captionIndex}`}
              span={[12, 12, captionColumnWidth]}
              mt={captionIndex > 0 ? ["5xl", "5xl", 0] : 0}
            >
              {icon && <Box as="img" mb="lg" width="3.25rem" height="3.25rem" src={icon.url} />}
              <Text as="div" typeStyle="body-sm" textColor="secondary">
                {label}
              </Text>
              <RevealText mt="xs" as="h5" typeStyle="heading-base">
                {heading}
              </RevealText>
              <RichText mt="xs">{description}</RichText>
              {!!download?.file && (
                <Box mt="lg">
                  <DownloadButton asset={download.file} label={download.label} />
                </Box>
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </Box>
  );
};

export default SingleImageSection;
