import React from "react";
import { useTheme } from "styled-components";
import { Container, Row, Col, Flex } from "~/components/Layout";
import { Text, RichText } from "~/components/Text";
import { hasEnoughContrast } from "~/helpers/color";
import { Thumbnail, Gradient } from "./styled";
import { GradientsSectionProps } from "./types";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";
import { useInView } from "react-hook-inview";

const GradientsSection: React.FC<GradientsSectionProps> = ({ caption, gradients }) => {
  const [animateChildren] = useInViewAnimation();
  const [inViewRef, inView] = useInView({ unobserveOnEnter: true });

  const { heading, subheading, description } = caption;
  const theme = useTheme();
  const minColSpan = 12 / Math.min(3, gradients.length);

  return (
    <Container py={["xl", "4xl"]} ref={animateChildren}>
      <Text typeStyle="heading-base" textColor="primary">
        {heading}
      </Text>

      <Text typeStyle="heading-sm" textColor="secondary" mt="xs">
        {subheading}
      </Text>

      <RichText mt="xs">{description}</RichText>
      <Row my="3xl">
        {gradients.map((gradient, i) => {
          const { left_color, right_color } = gradient;
          const { hex_code: leftColor, title: leftTitle } = left_color[0];
          const { hex_code: rightColor, title: rightTitle } = right_color[0];
          const borderWidth =
            hasEnoughContrast(leftColor, theme?.colors?.background) &&
            hasEnoughContrast(rightColor, theme?.colors?.background)
              ? "0px"
              : "1px";

          return (
            <Col key={i} span={[12, 12, minColSpan]} mb={["4xl", null, 0]}>
              <Gradient
                ref={inViewRef}
                $borderWidth={borderWidth}
                $leftColor={leftColor}
                $rightColor={rightColor}
                $isInView={inView}
              />

              <Flex justifyContent="space-between">
                <Flex alignItems="center">
                  <Thumbnail $borderWidth={borderWidth} $color={leftColor} />
                  <Col justifyContent="center">
                    <Text textColor="secondary" typeStyle="heading-xs">
                      {leftTitle}
                    </Text>
                    <Text textColor="body" typeStyle="body-sm">
                      {leftColor}
                    </Text>
                  </Col>
                </Flex>
                <Flex alignItems="center">
                  <Col textAlign="right">
                    <Text textColor="secondary" typeStyle="heading-xs">
                      {rightTitle}
                    </Text>
                    <Text textColor="body" typeStyle="body-sm">
                      {rightColor}
                    </Text>
                  </Col>
                  <Thumbnail $borderWidth={borderWidth} $color={rightColor} />
                </Flex>
              </Flex>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default GradientsSection;
