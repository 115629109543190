import React, { useMemo, useState, useRef, useEffect } from "react";
import { Container, Box, Flex } from "~/components/Layout";
import { ResponsiveImage } from "~/components/Image";
import { RichText, Text } from "~/components/Text";
import type { ImageAccordionProps } from "./types";
import { NumberLabel, AccordionItem } from "./styled";
import { AnimatePresence, motion } from "framer-motion";

const ImageAccordion: React.FC<ImageAccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const currentCaption = items[activeIndex]?.caption;
  const containerRef = useRef<HTMLDivElement | null>(null);

  const images = useMemo(() => {
    return items.map((item, itemIndex) => {
      return (
        <ResponsiveImage
          key={itemIndex}
          image={item.image}
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
          imgStyle={{
            width: "100%",
            height: "100%",
            userSelect: "none",
            pointerEvents: "none",
          }}
        />
      );
    });
  }, [items]);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const height = (containerRef.current.firstChild as HTMLElement)?.getBoundingClientRect().height;
      containerRef.current.style.height = height + "px";
    }
  }, []);

  return (
    <Box position="relative" my={["xl", "5xl"]}>
      <Flex
        flexDirection={["column", "row", "row"]}
        position="relative"
        borderY="1px solid"
        borderColor={"secondary"}
        ref={containerRef}
      >
        {images.map((image, imageIndex) => {
          return (
            <AccordionItem
              $active={imageIndex === activeIndex}
              key={imageIndex}
              onClick={() => {
                setActiveIndex(imageIndex);
              }}
            >
              {image}
              <NumberLabel>
                <Text typeStyle="heading-sm" textColor="background">
                  {String(imageIndex + 1)}
                </Text>
              </NumberLabel>
            </AccordionItem>
          );
        })}
      </Flex>
      <Container mt="4xl">
        <AnimatePresence exitBeforeEnter>
          <motion.div key={activeIndex} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Text typeStyle="heading-xl" textColor="primary">
              {currentCaption?.heading}
            </Text>
            <RichText mt="base">{currentCaption?.description}</RichText>
          </motion.div>
        </AnimatePresence>
      </Container>
    </Box>
  );
};

export default ImageAccordion;
