import React, { useMemo, useState } from "react";
import { ResponsiveVideo } from "~/components/Video";
import { ResponsiveImage } from "~/components/ResponsiveImage";
import type { BackgroundWrapperProps } from "./types";
import { BackgroundGradient, BackgroundImageWrapper } from "./styled";

const BackgroundWrapper: React.FC<BackgroundWrapperProps> = ({
  children,
  background_video,
  background_image,
  hasExtendedIntro,
  ...rest
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const hasBackgroundImage = background_image && background_image.length > 0;
  const hasBackgroundVideo = background_video && background_video.length > 0;

  // Memoize responsive background image. This avoids re-rendering the
  // image when state updates, which would otherwise cause the image to flash.
  const memoizedImage = useMemo(
    () =>
      background_image ? (
        <ResponsiveImage
          sources={background_image.map(({ url }, index) => ({
            isDefault: index === 0,
            src: url,
            breakpoint: ["sm", "md", "lg"][index],
          }))}
          isBackground
          lazy={false}
        />
      ) : null,
    [background_image],
  );

  // Memoize responsive background video. This avoids re-rendering the
  // video when state updates, which would otherwise cause the video to flash.
  const memoizedVideo = useMemo(
    () => (
      <ResponsiveVideo
        onLoad={() => setShowVideo(true)}
        style={{ height: "100%", width: "100%", objectPosition: "center", objectFit: "cover" }}
        video={background_video}
      />
    ),
    [background_video],
  );

  return (
    <BackgroundGradient $isExtendedIntro={hasExtendedIntro} {...rest}>
      {hasBackgroundVideo && <BackgroundImageWrapper zIndex={1}>{memoizedVideo}</BackgroundImageWrapper>}
      {hasBackgroundImage && (
        <BackgroundImageWrapper opacity={showVideo ? 0 : 1} zIndex={2}>
          {memoizedImage}
        </BackgroundImageWrapper>
      )}
      {children}
    </BackgroundGradient>
  );
};

export default BackgroundWrapper;
