import { transparentize } from "polished";
import type { DefaultTheme } from "styled-components";

const endPosition1 = 44;
const endPositionInverse1 = 100 - endPosition1;
const endPosition2 = 58;
const endPositionInverse2 = 100 - endPosition2;

export const getChapterHeroBackgroundGradient = (theme: DefaultTheme, progress = 1): string => {
  if (progress === 1) {
    return `linear-gradient(${theme.colors.background}, transparent ${endPosition1}%, transparent ${endPosition2}%, ${theme.colors.background})`;
  }

  const inverseProgress = 1 - progress;
  const fadeColor = transparentize(Math.max(0, inverseProgress), theme.colors.background);
  const position1 = inverseProgress * endPositionInverse1 + endPosition1;
  const position2 = inverseProgress * endPositionInverse2 + endPosition2;
  return `linear-gradient(${theme.colors.background}, transparent ${position1}%, transparent ${position2}%, ${fadeColor})`;
};
