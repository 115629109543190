import React from "react";
import styled from "styled-components";
import { variant, compose, space, layout, flex, SpaceProps, LayoutProps, FlexProps } from "styled-system";
import Icon from "~/components/Icon";
import { buttonColors, buttonStyles } from "~/styles/themes";

const BaseButton = styled.button`
  color: inherit;
  background: transparent;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.1em;

  text-decoration: none;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;

  cursor: ${({ theme }) => theme.cursors.pointer};
  touch-action: manipulation;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition-property: color, background-color, border-color;
  transition-duration: 200ms;
  transition-timing-function: ease;

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  & svg {
    margin-left: 0.75rem;
    height: 0.8em;
    width: 0.8em;
  }
`;

const buttonSystem = compose(space, layout, flex);

const colorVariants = variant({
  prop: "variant",
  variants: buttonColors,
});

const styleVariants = variant({
  prop: "styleVariant",
  variants: buttonStyles,
});

interface ButtonSystemProps extends SpaceProps, LayoutProps, FlexProps {}

interface StyledButtonProps extends ButtonSystemProps {
  variant: keyof typeof buttonColors;
  styleVariant: keyof typeof buttonStyles;
}

const parseStyleProp = (props: Omit<StyledButtonProps, "styleVariant">): StyledButtonProps => ({
  ...props,
  styleVariant: props.variant === "link" ? "link" : "default",
});

// eslint-disable-next-line prettier/prettier
export const StyledButton = styled(BaseButton)
  .attrs(parseStyleProp)(styleVariants, colorVariants, buttonSystem);

interface ButtonProps extends Partial<StyledButtonProps>, React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  icon?: string;
}

/**
 * Button styled-component.
 * @param variant used to select a preset style for the button
 * @param icon optional string referring to the type of icon to display in the button.
 * If left undefined, no icon will be rendered
 */
const Button = <T extends ButtonProps>({ children, variant = "primary", icon, ...rest }: T): JSX.Element => {
  return (
    <StyledButton variant={variant} {...rest}>
      {children}
      {!!icon && <Icon data-testid="buttonIcon" type={icon} />}
    </StyledButton>
  );
};

export default Button;
