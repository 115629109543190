import { useCallback, useRef } from "react";
import { useScrollEventListener } from "~/helpers/hooks/useScrollEventListener";

export function useParallax(enabled = false, parallaxRatio = 0.2): (node: Element | null) => void {
  const parallaxRef = useRef<HTMLElement | null>(null);

  const setParallaxRef = (node: Element | null) => {
    parallaxRef.current = node as HTMLElement;
    if (parallaxRef.current && parallaxRef.current.firstChild) {
      const firstChild = parallaxRef.current.firstChild as HTMLElement;
      parallaxRef.current.style.overflow = "hidden";
      firstChild.style.transformOrigin = "center";
    }
  };

  const handleScroll = useCallback(
    ({ scrollProgress = 0 }) => {
      const node = parallaxRef.current;
      if (node) {
        const currentProgress = scrollProgress;
        const firstChild = node.firstChild as HTMLElement;
        const translateAmount = (currentProgress - 0.5) * parallaxRatio * 100;
        firstChild.style.transform = `translateY(${translateAmount}%) scale(${1 + Math.abs(parallaxRatio)})`;
      }
    },
    [parallaxRatio],
  );

  useScrollEventListener(handleScroll, enabled, parallaxRef);

  return setParallaxRef;
}
