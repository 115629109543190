import React from "react";
import { TypestyleExamplesProps } from "./types";
import { Box, Container, Row, Col, Flex } from "~/components/Layout";
import { Text } from "~/components/Text";
import { TextSample, TypestyleHeader } from "./styled";
import { useTranslation } from "react-i18next";
import { fontWeightToTranslationKey } from "~/helpers/fontWeightToTranslationKey";
import RevealText from "../RevealText";

const TypestyleExamples: React.FC<TypestyleExamplesProps> = ({ full_width, typestyles }) => {
  const { t } = useTranslation();

  const minColSpan = full_width ? 12 : 6;
  const sizeString = t("size");
  const trackingString = t("tracking");
  const leadingString = t("leading");

  const examples = typestyles.map((typestyle, i) => {
    const { font, italic, name, size, text, leading, tracking, weight } = typestyle;
    const [fontFamily] = font;
    const fontStyle = t(fontWeightToTranslationKey(weight));
    return (
      <Col key={i} span={[12, 12, minColSpan]}>
        <Box borderTop="1px solid" borderColor="utility.secondary" mt="5xl">
          <TypestyleHeader>
            <Text textColor="secondary" typeStyle="body-xs">{`${name} • ${fontFamily.title} ${fontStyle}`}</Text>
            <Flex color="body">
              <Text typeStyle="body-xs">{`${sizeString}: ${size}pt`}</Text>
              <Text ml="2xl" typeStyle="body-xs">{`${trackingString}: ${tracking}`}</Text>
              <Text ml="2xl" typeStyle="body-xs">{`${leadingString}: ${leading}pt`}</Text>
            </Flex>
          </TypestyleHeader>
          <TextSample
            $fontFamily={fontFamily.title}
            $fontWeight={weight}
            $fontSize={size}
            $lineHeight={leading}
            $letterSpacing={tracking}
            $fontStyle={italic ? "italic" : "normal"}
          >
            <RevealText>{text}</RevealText>
          </TextSample>
        </Box>
      </Col>
    );
  });

  return (
    <Container py={["xl", "4xl"]}>
      <Row>{examples}</Row>
    </Container>
  );
};

export default TypestyleExamples;
