import React, { useRef } from "react";
import { Container, Box, Row, Col } from "~/components/Layout";
import type { ScrollingGalleryBackgroundProps } from "./types";
import { BackgroundLayer, StickyContainer, ItemImageContainer, GalleryBackgroundImage } from "./styled";

const ScrollingGalleryBackground: React.FC<ScrollingGalleryBackgroundProps> = ({
  responsiveImages,
  background_image,
  activeIndex,
  isNotMobile,
}) => {
  const imageSwitcherRef = useRef<HTMLDivElement | null>(null);

  return isNotMobile ? (
    <>
      <BackgroundLayer>
        <StickyContainer>{background_image && <GalleryBackgroundImage image={background_image} />}</StickyContainer>
      </BackgroundLayer>
      <BackgroundLayer>
        <StickyContainer ref={imageSwitcherRef}>
          <Container height="100%">
            <Row height="100%">
              <Col height="100%" span={8} offset={4}>
                <Box height="100%" width="100%" position="relative">
                  {responsiveImages.map((responsiveImage, itemImageIndex) => (
                    <ItemImageContainer
                      key={`scrolling-gallery-image-container--${itemImageIndex}`}
                      $isVisible={itemImageIndex === activeIndex}
                    >
                      {responsiveImage}
                    </ItemImageContainer>
                  ))}
                </Box>
              </Col>
            </Row>
          </Container>
        </StickyContainer>
      </BackgroundLayer>
    </>
  ) : (
    <></>
  );
};

export default ScrollingGalleryBackground;
