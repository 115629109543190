import styled, { css } from "styled-components";
import cssSystem from "@styled-system/css";
import { Container } from "~/components/Layout";
import { headerSafeHeight } from "~/components/Header/constants";
import { NextChapterArrow } from "~/components/Decorators";
import { Text } from "~/components/Text";
import { PageTransitionReactiveProps } from "./types";
import { transitionDuration } from "./constants";

export const NextChapterContainer = styled.div`
  position: relative;
  height: calc(1.75 * var(--viewport-height));
  &::before,
  &::after {
    content: "";
    flex: 1;
  }
`;

export const ArrowIcon = styled(NextChapterArrow)<PageTransitionReactiveProps>`
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 2.5rem;
  width: 5rem;
  cursor: ${({ theme }) => theme.cursors.pointer};
  z-index: 20;
  transition: transform 0.3s ease;
  transform: translate(-50%, ${({ $pageTransitionActive }) => ($pageTransitionActive ? 100 : 0)}%);
`;

export const ChapterHeroContainer = styled(Container)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(0.75 * ${headerSafeHeight});
`;

export const ChapterHeroLabel = styled(Text).attrs({ forwardedAs: "div" })`
  transition: opacity 1s ease;
`;

export const ChapterHeroTitleContainer = styled.div<PageTransitionReactiveProps>`
  transition: transform 0.8s ease-in, opacity 0.5s ease-in;
  transform: translateY(${({ $pageTransitionActive }) => ($pageTransitionActive ? 200 : 0)}%);
  opacity: ${({ $pageTransitionActive }) => ($pageTransitionActive ? 0 : 1)};
`;

export const TransitionBox = styled.div<PageTransitionReactiveProps>`
  position: ${({ $pageTransitionActive }) => ($pageTransitionActive ? "fixed" : "sticky")};
  width: 100%;
  top: 0;
  cursor: ${({ theme }) => theme.cursors.pointer};
  user-select: none;
  overflow: hidden;
`;

export const BackgroundWrapper = styled.div<PageTransitionReactiveProps>`
  display: block;
  width: 100%;

  transform-origin: center top;
  animation-fill-mode: both;

  transition: height ${transitionDuration * 0.7}ms ${transitionDuration * 0.3}ms ease,
    transform ${transitionDuration * 0.7}ms ${transitionDuration * 0.3}ms ease;
  ${({ $pageTransitionActive }) =>
    cssSystem({
      height: $pageTransitionActive
        ? [`calc(0.9 * ${headerSafeHeight})`, headerSafeHeight]
        : [`calc(0.75 * ${headerSafeHeight})`],
      transform: $pageTransitionActive
        ? [`translateY(calc(-0.1 * ${headerSafeHeight}))`, "translateY(0)"]
        : "translateY(0)",
    })()}

  ${({ $pageTransitionActive }) =>
    $pageTransitionActive
      ? css`
          position: fixed;
          bottom: 0;
        `
      : css`
          position: absolute;
          top: 0;
        `}
`;

export const GradientBox = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: calc(100% + 1px);
  top: -1;
  left: 0;
  overflow: hidden;
`;
