import React, { useCallback, useRef, useMemo, useEffect } from "react";
import { useTheme } from "styled-components";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useInView } from "react-hook-inview";
import { Flex, Row, Col } from "~/components/Layout";
import { Rule } from "~/components/Decorators";
import { getChapterHeroBackgroundGradient } from "~/components/ChapterHero/helpers";
import { Text } from "~/components/Text";
import { ResponsiveImage } from "~/components/ResponsiveImage";
import { BackgroundImageWrapper } from "~/components/ChapterHero/styled";
import { getChapterUrl } from "~/helpers/getChapterUrl";
import { useScrollEventListener } from "~/helpers/hooks/useScrollEventListener";
import { useUI } from "~/helpers/hooks/useUI";
import { NextChapterProps } from "./types";
import { transitionDuration } from "./constants";
import {
  TransitionBox,
  GradientBox,
  ArrowIcon,
  ChapterHeroContainer,
  ChapterHeroLabel,
  NextChapterContainer,
  ChapterHeroTitleContainer,
  BackgroundWrapper,
} from "./styled";

/**
 * The `NextChapter` component acts as a preview for the chapter (page) that immediately
 * follows the chapter that the user is currently viewing, as well as providing
 * the user with the ability to navigate to the next chapter without having to
 * manually click a nav item.
 * @param chapter the chapter data object representing the target chapter
 * @param brandSlug the slug for the brand book in which the chapter exists
 */
const NextChapter: React.FC<NextChapterProps> = ({ chapter, brandSlug }) => {
  const theme = useTheme();
  const { t, navigate } = useI18next();
  const { pageTransitionActive, notifyPageTransitionStarted } = useUI();
  const [inViewRef, inView] = useInView();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentsRef = useRef<HTMLDivElement | null>(null);
  const progressIndicatorRef = useRef<HTMLDivElement | null>(null);
  const lockTimeoutRef = useRef<number | null>(null);
  const navigateTimeoutRef = useRef<number | null>(null);

  const { slug, chapter_hero } = chapter;
  const url = getChapterUrl(brandSlug, slug);
  const hasBackgroundImage = chapter_hero.background_image && chapter_hero.background_image.length >= 0;

  const setContainerRef = useCallback(
    (node: HTMLDivElement) => {
      inViewRef(node);
      containerRef.current = node;
    },
    [inViewRef],
  );

  // notify the UI context that the transition has started,
  // then start the timeout to actually navigate to the target chapter's page
  const startTransition = useCallback(() => {
    notifyPageTransitionStarted();
    navigateTimeoutRef.current = window.setTimeout(() => navigate(url), transitionDuration);
  }, [navigate, notifyPageTransitionStarted, url]);

  const handleScroll = useCallback(
    (scrollData?: Record<string, number>) => {
      if (!pageTransitionActive) {
        if (containerRef.current && contentsRef.current && progressIndicatorRef.current) {
          const { top: containerTop, height: containerHeight } = containerRef.current.getBoundingClientRect();
          const { top: contentsTop, height: contentsHeight } = contentsRef.current.getBoundingClientRect();
          // compute how far the user has to scroll
          const totalScrollDistance = containerHeight - contentsHeight;
          // compute how far the user has already scrolled
          const currentScrollDistance = contentsTop - containerTop;
          // compute a 0->1 value to represent the user's scroll progress
          const scrollProgress = parseFloat((currentScrollDistance / totalScrollDistance).toFixed(2));

          // update gradient (progress indicator) to represent scroll progress
          progressIndicatorRef.current.style.background = getChapterHeroBackgroundGradient(theme, scrollProgress);

          // set the height of the sticky element
          contentsRef.current.style.top = `${window.innerHeight - contentsHeight}px`;

          // clear timeout if user has scrolled up
          if (lockTimeoutRef.current && scrollProgress < 1) {
            window.clearTimeout(lockTimeoutRef.current);
            lockTimeoutRef.current = null;
          }

          // start transition if scrolled to bottom
          if (!lockTimeoutRef.current && scrollData && scrollProgress === 1) {
            lockTimeoutRef.current = window.setTimeout(startTransition, 500);
          }
        }
      }
    },
    [pageTransitionActive, startTransition, theme],
  );

  // handles a click event on the component, causing the scroll-navigation to auto-progress and execute.
  const handleClickToProgress = useCallback(() => {
    window.scrollTo({
      top: (document.scrollingElement || document.body).scrollHeight,
      behavior: "smooth",
    });
  }, []);

  useScrollEventListener(handleScroll, inView);

  // Memoize responsive background image. This avoids re-rendering the
  // image when state updates, which would otherwise cause the image to flash.
  const memoizedImage = useMemo(
    () =>
      chapter_hero.background_image ? (
        <ResponsiveImage
          sources={chapter_hero.background_image.map(({ url }, index) => ({
            isDefault: index === 0,
            src: url,
            breakpoint: ["sm", "md", "lg"][index],
          }))}
          isBackground
        />
      ) : null,
    [chapter_hero.background_image],
  );

  // ensure timeouts are all cleanued up when component is unmounted.
  useEffect(() => {
    return () => {
      if (navigateTimeoutRef.current) window.clearTimeout(navigateTimeoutRef.current);
      if (lockTimeoutRef.current) window.clearTimeout(lockTimeoutRef.current);
    };
  }, []);

  return (
    <NextChapterContainer id="nextChapter" ref={setContainerRef}>
      <TransitionBox ref={contentsRef} onClick={handleClickToProgress} $pageTransitionActive={pageTransitionActive}>
        <BackgroundWrapper $pageTransitionActive={pageTransitionActive}>
          <GradientBox ref={progressIndicatorRef}>
            {hasBackgroundImage && <BackgroundImageWrapper>{memoizedImage}</BackgroundImageWrapper>}
          </GradientBox>
        </BackgroundWrapper>
        <ChapterHeroContainer>
          <Flex flexDirection="column" alignItems="center">
            <ChapterHeroLabel
              typeStyle="heading-sm"
              mb="base"
              textColor="secondary"
              opacity={pageTransitionActive ? 0 : 1}
            >
              {t("next_chapter")}
            </ChapterHeroLabel>
            <ChapterHeroTitleContainer $pageTransitionActive={pageTransitionActive}>
              <Text as="h3" textColor="primary" typeStyle={["heading-2xl", "heading-3xl", "heading-5xl"]}>
                {chapter_hero.title.heading}
              </Text>
            </ChapterHeroTitleContainer>
          </Flex>
          <Row mt="2xl" opacity={0}>
            <Col span={[12, 10, 6, 6]} offset={[0, 1, 3, 3]}>
              <Rule size="large" />
            </Col>
          </Row>
        </ChapterHeroContainer>
        <ArrowIcon $pageTransitionActive={pageTransitionActive} />
      </TransitionBox>
    </NextChapterContainer>
  );
};

export default NextChapter;
