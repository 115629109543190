import React, { useState, useRef, useEffect, useCallback } from "react";
import { useInView } from "react-hook-inview";
import { useTheme } from "styled-components";
import { Box, Container } from "~/components/Layout";
import { ResponsiveImage } from "~/components/Image";
import { useMatchMedia } from "~/helpers/hooks/useMatchMedia";
import DesktopCarouselItems from "./DesktopCarouselItems";
import { TimedImageCarouselProps } from "./types";
import { ImageItem } from "./styled";
import MobileCarouselItems from "./MobileCarouselItems";

const TimedImageCarousel: React.FC<TimedImageCarouselProps> = ({ full_bleed, item_duration, items }) => {
  const theme = useTheme();
  const isNotMobile = useMatchMedia(theme.mediaQueries.md);
  const [inViewRef, inView] = useInView();
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const ImageContainer = full_bleed ? Box : Container;
  const intervalRef = useRef<number | null>(null);

  const updateActiveItem = useCallback(() => {
    setActiveItemIndex((prevActiveItemIndex) => (prevActiveItemIndex + 1) % items.length);
  }, [items.length]);

  const stopInterval = useCallback(() => {
    if (intervalRef.current) window.clearInterval(intervalRef.current);
  }, []);

  const startInterval = useCallback(() => {
    stopInterval();
    intervalRef.current = window.setInterval(updateActiveItem, item_duration * 1000);
  }, [item_duration, stopInterval, updateActiveItem]);

  const handleItemClick = useCallback(
    (index: number) => {
      stopInterval();
      setActiveItemIndex(index % items.length);
      startInterval();
    },
    [items.length, startInterval, stopInterval],
  );

  useEffect(() => {
    if (inView) {
      startInterval();
      return stopInterval;
    }
  }, [inView, item_duration, startInterval, stopInterval, updateActiveItem]);

  return (
    <Box ref={inViewRef} my="5xl">
      <ImageContainer>
        <Box position="relative" overflow="hidden">
          {items.map((item, itemIndex) => (
            <ImageItem key={itemIndex} $isFirst={itemIndex === 0} $isActive={itemIndex === activeItemIndex}>
              <ResponsiveImage image={item.image} />
            </ImageItem>
          ))}
        </Box>
      </ImageContainer>
      <Container mt={["5xl", "5xl", "3xl"]}>
        {isNotMobile ? (
          <DesktopCarouselItems
            items={items}
            inView={inView}
            item_duration={item_duration}
            handleItemClick={handleItemClick}
            activeItemIndex={activeItemIndex}
          />
        ) : (
          <MobileCarouselItems
            items={items}
            inView={inView}
            item_duration={item_duration}
            handleItemClick={handleItemClick}
            activeItemIndex={activeItemIndex}
          />
        )}
      </Container>
    </Box>
  );
};

export default TimedImageCarousel;
