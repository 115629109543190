import React, { useRef, useCallback } from "react";
import { useInView } from "react-hook-inview";
import { LeftImageContainer, ImagesContainer } from "./styled";
import type { ImageComparisonInnerProps } from "./types";

import { useScrollEventListener } from "~/helpers/hooks/useScrollEventListener";
import { clamp } from "~/helpers/clamp";

const MobileImageComparison: React.FC<ImageComparisonInnerProps> = ({ rightImage, leftImage }) => {
  const [inViewRef, inView] = useInView();
  const leftImageContainerRef = useRef<HTMLDivElement | null>(null);
  const imagesContainerRef = useRef<HTMLDivElement | null>(null);

  const setContainerRef = useCallback(
    (node: HTMLDivElement) => {
      inViewRef(node);
      imagesContainerRef.current = node;
    },
    [inViewRef],
  );

  const handleScroll = ({ scrollProgress }: { scrollProgress: number }) => {
    if (leftImageContainerRef.current) {
      leftImageContainerRef.current.style.height = `${clamp((scrollProgress - 0.4) * 6, 0, 1) * 100}%`;
    }
  };

  useScrollEventListener(handleScroll, inView, imagesContainerRef);

  return (
    <ImagesContainer ref={setContainerRef}>
      {rightImage}

      <LeftImageContainer ref={leftImageContainerRef}>{leftImage}</LeftImageContainer>
    </ImagesContainer>
  );
};

export default MobileImageComparison;
