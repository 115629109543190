import styled from "styled-components";
import { zoomCircleSize } from "./constants";

export const ZoomedImageContainer = styled.div`
  user-select: none;
`;

export const ZoomedImage = styled.div`
  position: fixed;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  clip-path: circle(${zoomCircleSize} at 50% 50%);
  transition: opacity 0.3s ease;
  opacity: 0;
  z-index: 50;
  user-select: none;
`;

export const ZoomCircle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(2 * ${zoomCircleSize});
  height: calc(2 * ${zoomCircleSize});
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  transition: opacity 0.3s ease;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  z-index: 50;
  user-select: none;
`;
