import React from "react";
import { Flex, Box } from "~/components/Layout";
import { Text } from "~/components/Text";
import { ResponsiveImage } from "~/components/Image";
import { useParallax } from "~/helpers/hooks/useParallax";
import { ImageSpecItemProps } from "./types";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";

const ImageSpecItem: React.FC<ImageSpecItemProps> = ({
  main_image,
  enable_parallax,
  title,
  primary_info,
  secondary_info,
}) => {
  const [inViewAnimationRef, inView] = useInViewAnimation();
  const parallaxRef = useParallax(inView && enable_parallax, 0.3);
  const titleInfoString = `${title} • ${primary_info}`;
  return (
    <div ref={inViewAnimationRef}>
      <Box ref={parallaxRef} position="relative" mb="3xl" width="100%" className="animate-inview-scale">
        <ResponsiveImage image={main_image} />
      </Box>
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        borderTop="1px solid"
        borderColor="utility.primary"
        pt="sm"
      >
        <Text typeStyle="heading-xs" textColor="secondary">
          {titleInfoString}
        </Text>
        <Text mt={["xs", 0]} typeStyle="body-xs" textColor="body">
          {secondary_info}
        </Text>
      </Flex>
    </div>
  );
};

export default ImageSpecItem;
