import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { useInView } from "react-hook-inview";
import { Container, Row, Col, Box } from "~/components/Layout";
import { RichText } from "~/components/Text";
import { BookendText } from "~/components/Decorators";
import { ResponsiveAsset } from "~/components/Asset";
import RevealText from "~/components/RevealText";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";
import { useParallax } from "~/helpers/hooks/useParallax";
import type { SectionIntroProps } from "./types";

const BackgroundGradient = styled.div`
  position: relative;
  background-image: ${({ theme }) => {
    const { background } = theme.colors;
    const transparentBackground = transparentize(1, background);
    return `linear-gradient(${background}, ${transparentBackground} 40%, ${transparentBackground} 60%, ${background})`;
  }};
`;

/**
 * Introduces a new "section" in a chapter. Similar to FeatureBreak, but with a
 * larger heading and an optional label with "decorators" attached. Supports image
 * or video backgrounds from Contentstack.
 */
const SectionIntro: React.FC<SectionIntroProps> = ({
  label,
  heading,
  description,
  background_media,
  enable_parallax,
}) => {
  const [animateChildren] = useInViewAnimation();
  const [inViewRef, inView] = useInView();
  const parallaxRef = useParallax(inView && enable_parallax, 0.2);

  return (
    <BackgroundGradient>
      <Container ref={inViewRef} textAlign="center">
        <Row py={["5xl", "8xl"]} justifyContent="center">
          <Col ref={animateChildren} span={[12, 8, 6]}>
            <BookendText typeStyle={["heading-xs", "heading-sm"]} forwardedAs="div" textColor="secondary" mb="sm">
              {label}
            </BookendText>
            <RevealText as="h3" typeStyle={["heading-xl", "heading-3xl"]} mb="lg">
              {heading}
            </RevealText>
            <RichText>{description}</RichText>
          </Col>
        </Row>
      </Container>
      {!!background_media && background_media?.length > 0 && (
        <Box zIndex={-1} position="absolute" height="100%" width="100%" top="0" left="0" ref={parallaxRef}>
          <ResponsiveAsset
            asset={background_media}
            imageProps={{ objectPosition: "center", objectFit: "cover" }}
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      )}
    </BackgroundGradient>
  );
};

export default SectionIntro;
