import React from "react";
import { useInView } from "react-hook-inview";
import { Text } from "~/components/Text";
import type { TextProps } from "~/components/Text/Text";
import { AnimToken, WordWrapper } from "./styled";

interface RevealTextProps extends TextProps {
  children?: string;
  type?: "letter" | "word";
  delay?: number;
  interval?: number;
  threshold?: number;
}

const RevealText: React.FC<RevealTextProps> = ({
  children,
  type = "word",
  delay = 0,
  interval: intervalOverride,
  threshold = 0.75,
  ...rest
}) => {
  const [inViewRef, inView] = useInView({ threshold, unobserveOnEnter: true });
  const interval: number = intervalOverride || (type === "letter" ? 40 : 80);
  const words: string[] | undefined = children
    ?.split(" ")
    .reduce((acc: string[], current: string, index: number): string[] => {
      if (index > 0) acc.push(" ");
      acc.push(current);
      return acc;
    }, []);

  return (
    <Text ref={inViewRef} {...rest}>
      {words?.map((word, wordIndex) => {
        const prevStringLength = words.slice(0, wordIndex).reduce((length, word) => length + word.length, 0);
        if (word === " ") return word;
        return type === "letter" ? (
          <WordWrapper key={`${word}${wordIndex}`}>
            {word.split("")?.map((token, tokenIndex) =>
              token === " " ? (
                token
              ) : (
                <AnimToken
                  $isInView={inView}
                  $interval={interval}
                  $index={prevStringLength + tokenIndex}
                  $delay={delay}
                  key={`${token}${prevStringLength + tokenIndex}`}
                >
                  <span>{token}</span>
                </AnimToken>
              ),
            )}
          </WordWrapper>
        ) : (
          <AnimToken
            key={`${word}${wordIndex}`}
            $isInView={inView}
            $interval={interval}
            $index={wordIndex}
            $delay={delay}
          >
            <span>{word}</span>
          </AnimToken>
        );
      })}
    </Text>
  );
};

export default RevealText;
