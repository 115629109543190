import React, { useRef, useEffect, useCallback } from "react";
import type { ImageComparisonInnerProps } from "./types";

import { ArrowContainer, VerticalLine, LeftImageContainer, Divider, ImagesContainer } from "./styled";
import { clamp } from "~/helpers/clamp";

const Arrows = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6258 11.9999V0L0 11.9999L10.6258 23.9998V11.9999Z" />
      <path d="M15.3742 0.000153772V12.0001V24L26 12.0001L15.3742 0.000153772Z" />
    </svg>
  );
};

const DesktopImageComparison: React.FC<ImageComparisonInnerProps> = ({ rightImage, leftImage }) => {
  const imagesContainerRef = useRef<HTMLDivElement | null>(null);
  const leftImageContainerRef = useRef<HTMLDivElement | null>(null);
  const dividerRef = useRef<HTMLDivElement | null>(null);

  const updateProgress = (event?: MouseEvent | React.MouseEvent) => {
    if (imagesContainerRef.current) {
      const { left: containerLeft, width: containerWidth } = imagesContainerRef.current.getBoundingClientRect();

      const relativeX = event ? event.clientX - containerLeft : containerWidth / 2;
      const progress = clamp(relativeX / containerWidth, 0, 1) * 100;

      if (leftImageContainerRef && leftImageContainerRef.current) {
        leftImageContainerRef.current.style.width = `${progress}%`;
      }

      if (dividerRef && dividerRef.current) {
        dividerRef.current.style.left = `${progress}%`;
      }
    }
  };

  const onMouseMove = useCallback((event: MouseEvent) => {
    updateProgress(event);
  }, []);

  const onMouseDown = (event: React.MouseEvent) => {
    window.addEventListener("mousemove", onMouseMove);
    updateProgress(event);
  };

  const onMouseUp = useCallback(() => {
    window.removeEventListener("mousemove", onMouseMove);
  }, [onMouseMove]);

  useEffect(() => {
    window.addEventListener("mouseup", onMouseUp);
    updateProgress(); // start the divider at halfway mark

    return () => {
      onMouseUp();
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseUp]);

  return (
    <ImagesContainer onMouseDown={onMouseDown} ref={imagesContainerRef}>
      {rightImage}

      <LeftImageContainer ref={leftImageContainerRef}>{leftImage}</LeftImageContainer>
      <Divider ref={dividerRef}>
        <VerticalLine />
        <ArrowContainer>
          <Arrows />
        </ArrowContainer>
      </Divider>
    </ImagesContainer>
  );
};

export default DesktopImageComparison;
