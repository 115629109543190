import React from "react";
import { Container, Box, Col, Row } from "~/components/Layout";
import RevealText from "~/components/RevealText";
import { RichText, Text } from "~/components/Text";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";
import type { CaptionListProps } from "./types";

const CaptionList: React.FC<CaptionListProps> = ({ items }) => {
  const [animateChildren] = useInViewAnimation();
  const minColSpan = 12 / Math.min(4, items.length);

  return (
    <Container py={["xl", "4xl"]}>
      <Row ref={animateChildren}>
        {!!items &&
          !!items.length &&
          items.map((item, itemIndex) => {
            const { description, heading, subheading, icon } = item;

            return (
              <Col key={itemIndex} span={[12, 12, minColSpan]} mb={["4xl", "4xl", 0]}>
                {!!icon && <Box as="img" width="2.5rem" height="2.5rem" src={icon.url}></Box>}
                <Text typeStyle="heading-base" textColor="secondary" mt="sm">
                  {subheading}
                </Text>
                <RevealText delay={itemIndex * 300} typeStyle="heading-xl" textColor="primary" mt="sm">
                  {heading}
                </RevealText>
                <RichText mt="sm" typeStyles={{ p: "body-sm" }}>
                  {description}
                </RichText>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};

export default CaptionList;
