import React from "react";
import { Text, RichText } from "~/components/Text";
import { Box, Row, Col } from "~/components/Layout";
import DownloadButton from "~/components/DownloadButton";
import { CarouselItemsProps } from "./types";
import { ClickZone, ProgressBar, CarouselCaption } from "./styled";

const DesktopCarouselItems: React.FC<CarouselItemsProps> = ({
  inView,
  items,
  activeItemIndex,
  handleItemClick,
  item_duration,
}) => {
  const captionColumnWidth = 12 / items.length;
  return (
    <Row>
      {items.map(({ caption: { icon, heading, description, download: [download] = [] } }, itemIndex) => (
        <Col
          key={`single-image-section-caption--${heading}-${itemIndex}`}
          span={[12, 12, captionColumnWidth]}
          mt={itemIndex > 0 ? ["5xl", "5xl", 0] : 0}
        >
          <ClickZone onClick={itemIndex !== activeItemIndex ? () => handleItemClick(itemIndex) : undefined}>
            <ProgressBar $isActive={itemIndex === activeItemIndex && inView} $itemDuration={item_duration} />
            {!!icon && <Box as="img" mb="sm" width="2.25rem" height="2.25rem" src={icon?.url} />}
            <CarouselCaption $isActive={itemIndex === activeItemIndex}>
              <Text as="h5" typeStyle="heading-base">
                {heading}
              </Text>
              <RichText typeStyles={{ p: "body-sm" }} mt="xs">
                {description}
              </RichText>
              {!!download?.file && (
                <Box mt="lg">
                  <DownloadButton asset={download.file} label={download.label} />
                </Box>
              )}
            </CarouselCaption>
          </ClickZone>
        </Col>
      ))}
    </Row>
  );
};

export default DesktopCarouselItems;
