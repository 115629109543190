import React from "react";
import { graphql } from "gatsby";
import type { ChapterPageProps } from "~/types/chapter";

// Sections
import SectionResolver from "~/components/SectionResolver";
import ChapterHero from "~/components/ChapterHero";
import SectionIntro from "~/components/SectionIntro";
import MultiImage from "~/components/MultiImage";
import FeatureBreak from "~/components/FeatureBreak";
import NextChapter from "~/components/NextChapter";
import SingleImageSection from "~/components/SingleImageSection";
import ImageComparison from "~/components/ImageComparison";
import TimedImageCarousel from "~/components/TimedImageCarousel";
import TypefaceShowcase from "~/components/TypefaceShowcase";
import Footer from "~/components/Footer";
import ColorsSection from "~/components/ColorsSection";
import GradientsSection from "~/components/GradientsSection";
import CaptionList from "~/components/CaptionList";
import VideoEmbedSection from "~/components/VideoEmbedSection";
import ScrollingGallery from "~/components/ScrollingGallery/index";
import ImageSpecSection from "~/components/ImageSpecSection/index";
import TypefaceWeights from "~/components/TypefaceWeights";
import TypestyleExamples from "~/components/TypestyleExamples";
import ImageCarousel from "~/components/ImageCarousel";
import DownloadSection from "~/components/DownloadSection";
import GoogleDriveLinks from "~/components/GoogleDriveLinksSection";
import ImageAccordion from "~/components/ImageAccordion";

const registry = {
  section_intro: SectionIntro,
  feature_break: FeatureBreak,
  multi_image_section: MultiImage,
  single_image_section: SingleImageSection,
  image_comparison_section: ImageComparison,
  colors_section: ColorsSection,
  gradients_section: GradientsSection,
  timed_image_carousel: TimedImageCarousel,
  caption_list: CaptionList,
  scrolling_gallery: ScrollingGallery,
  typeface_showcase: TypefaceShowcase,
  video_embed_section: VideoEmbedSection,
  image_spec_section: ImageSpecSection,
  typeface_weights_section: TypefaceWeights,
  typestyle_examples: TypestyleExamples,
  image_carousel: ImageCarousel,
  download_section: DownloadSection,
  google_drive_links: GoogleDriveLinks,
  image_accordion: ImageAccordion,
};

const Chapter: React.FC<ChapterPageProps> = ({ data: { page } }) => {
  const {
    slug,
    chapter_hero,
    brand: [brand], // destructure contentstack "brand" group array
    sections,
  } = page;
  const currentChapterIndex = brand.chapters.findIndex((chapter) => chapter.slug === slug);
  const nextChapter = currentChapterIndex >= 0 ? brand.chapters[currentChapterIndex + 1] : null;
  const { footer: [footer] = [], slug: brandSlug } = brand;

  return (
    <>
      <div id="chapterContents">
        <ChapterHero {...chapter_hero} />
        <SectionResolver sections={sections} registry={registry} />
      </div>
      {!nextChapter && !!footer && <Footer {...footer} />}
      {!!nextChapter && <NextChapter brandSlug={brandSlug} chapter={nextChapter} />}
    </>
  );
};

export default Chapter;

export const query = graphql`
  query($language: String!, $uid: String!) {
    ...MetaFragment
    ...HeaderFragment

    # This is the query that passes data to the page via the data property.
    page: contentstackChapter(publish_details: { locale: { eq: $language } }, uid: { eq: $uid }) {
      __typename
      slug
      meta {
        page_title
        page_description
        share_image {
          url
        }
      }
      brand {
        meta {
          title
          description
          share_image {
            ...Image
          }
        }
        slug
        favicon {
          url
        }
        logo {
          ...Image
        }
        chapters {
          slug
          meta {
            page_title
          }
          ...ChapterHeroFragment
        }
        footer {
          ...Footer
        }
      }

      ...ChapterHeroFragment
      ...ChapterSectionsFragment
    }
    # Mandatory query for i18next. All pages need this query.
    ...TranslationQueryFragment
  }
`;
