import React from "react";
import { Container, Row, Col } from "~/components/Layout";
import MultiImageItem from "./MultiImageItem";
import type { MultiImageProps } from "./types";

/**
 * Section with multiple images, each with an optional caption block.
 */
const MultiImage: React.FC<MultiImageProps> = ({ items }) => {
  const colSpan = 12 / Math.min(4, items.length);

  return (
    <Container pt={["xl", "5xl"]}>
      <Row>
        {items.map((item, itemIndex) => (
          <Col key={itemIndex} span={[12, 6, colSpan]} mb="5xl">
            <MultiImageItem index={itemIndex} {...item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default MultiImage;
