import styled, { css, keyframes } from "styled-components";

export const progressBarGrow = keyframes`
  from {
    transform: scaleX(0) translateY(-50%);
  }
  to {
    transform: scaleX(1) translateY(-50%);
  }
`;

export const ProgressBar = styled.div<{ $isActive: boolean; $itemDuration: number }>`
  position: relative;
  width: 100%;
  height: 2px;
  cursor: ${({ theme }) => theme.cursors.pointer};

  /* extra padding to increase click area */
  padding: 1.5rem 0;
  margin-top: -1.5rem;
  margin-bottom: calc(2.25rem - 1.5rem);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    transform-origin: left center;
    background-color: ${({ theme }) => theme.colors.secondary};
    z-index: 1;
    ${({ $isActive, $itemDuration }) =>
      $isActive
        ? css`
            animation: ${progressBarGrow} ${$itemDuration}s linear;
          `
        : css`
            transform: scaleX(0) translateY(-50%);
          `}
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 0;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const ImageItem = styled.div<{ $isFirst: boolean; $isActive: boolean }>`
  position: ${({ $isFirst }) => ($isFirst ? "relative" : "absolute")};
  ${({ $isFirst }) =>
    !$isFirst &&
    css`
      width: 100%;
      top: 0;
      left: 0;
    `}
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

export const ClickZone = styled.div`
  cursor: ${({ theme }) => theme.cursors.pointer};
`;

export const CarouselCaption = styled.div<{ $isActive: boolean }>`
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
  pointer-events: ${({ $isActive }) => ($isActive ? "auto" : "none")};
  cursor: ${({ theme }) => theme.cursors.pointer};
  transition: opacity 0.3s ease;
`;
