import React from "react";
import styled, { css, useTheme } from "styled-components";
import { useInView } from "react-hook-inview";
import { Text } from "~/components/Text";
import { Box } from "~/components/Layout/Box";
import { ThemeDecorators } from "~/styles/themes/types";

/**
 * Intended to wrap Text
 */
export const BookendText = styled(Text)`
  display: inline-block;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.75em;
    height: 100%;
    margin: 0 0.375em;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &::before {
    right: 100%;
    background-image: url(${({ theme }) => theme.decorators.label.left});
  }
  &::after {
    left: 100%;
    background-image: url(${({ theme }) => theme.decorators.label.right});
  }
`;

export interface RuleProps {
  size?: keyof ThemeDecorators["rule"];
}

const RuleImage = styled(Box)<{ $isInView: boolean }>`
  transition: clip-path 1s ease, opacity 0.7s ease;
  transition-delay: 0.6s;
  ${({ $isInView }) =>
    $isInView
      ? css`
          opacity: 1;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        `
      : css`
          opacity: 0;
          clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%);
        `}
`;

export const Rule: React.FC<RuleProps> = ({ size = "small", ...rest }) => {
  const { decorators } = useTheme();
  const [inViewRef, inView] = useInView({ unobserveOnEnter: true });
  return (
    <RuleImage as="img" ref={inViewRef} src={decorators.rule[size]} alt="" width="100%" $isInView={inView} {...rest} />
  );
};

export const NextChapterArrow: React.FC = ({ ...rest }) => {
  const { decorators } = useTheme();
  return <Box as="img" src={decorators.nextChapter} alt="" width="100%" {...rest} />;
};
