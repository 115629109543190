import React from "react";
import { Box, Flex, Container, Row, Col } from "~/components/Layout";
import { Text } from "~/components/Text";
import type { GoogelDriveLinksProps } from "./types";
import * as Styled from "~/components/DownloadLinks/styled";
import Button from "~/components/Button";
import Icon from "~/components/Icon";

const GoogelDriveLinks: React.FC<GoogelDriveLinksProps> = ({ label, heading, links }) => {
  return (
    <Container py={["4xl", "7xl"]}>
      <Row alignItems="center" justifyContent="center">
        <Col span={[12, 8]}>
          <Flex
            flexDirection={["column", "row"]}
            justifyContent={[null, "space-between"]}
            alignItems={[null, "flex-end"]}
          >
            <Box mb={["xl", 0]}>
              <Text as="div" typeStyle="heading-xs" textColor="secondary">
                {label}
              </Text>
              <Text as="h2" typeStyle="heading-base" textColor="primary" mt="base">
                {heading}
              </Text>
            </Box>
            {!!links.package_link?.href && (
              <Button as="a" href={links.package_link.href}>
                {links.package_link.title}
              </Button>
            )}
          </Flex>
          <Box mt={["xl", "5xl"]} role="table">
            <div role="rowgroup">
              {links.individual_links.map((link) => (
                <Styled.DownloadLinkRow
                  as="a"
                  key={link.href}
                  href={link.href}
                  target="_blank"
                  alignItems="center"
                  role="row"
                >
                  <Text as="div" role="cell" typeStyle="body-sm" flex="1">
                    {link.title}
                  </Text>
                  <Box role="cell" textAlign="right" gridColumn="4">
                    <Icon type="arrow-up-right" />
                  </Box>
                </Styled.DownloadLinkRow>
              ))}
            </div>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default GoogelDriveLinks;
