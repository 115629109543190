import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { useInView } from "react-hook-inview";
import { RichText } from "~/components/Text";
import { Container, Row, Col, Box } from "~/components/Layout";
import { Rule } from "~/components/Decorators";
import { ResponsiveAsset } from "~/components/Asset";
import RevealText from "~/components/RevealText";
import { useInViewAnimation } from "~/helpers/hooks/useInViewAnimation";
import { useParallax } from "~/helpers/hooks/useParallax";
import { FeatureBreakProps } from "./types";

const BackgroundGradient = styled.div`
  position: relative;
  background-image: ${({ theme }) => {
    const { background } = theme.colors;
    const transparentBackground = transparentize(1, background);
    return `linear-gradient(${background}, ${transparentBackground} 40%, ${transparentBackground} 60%, ${background})`;
  }};
`;

/**
 * A short copy section to break up features and asset sections with more
 * detailed descriptions. Supports image backgrounds from Contentstack.
 */
const FeatureBreak: React.FC<FeatureBreakProps> = ({
  center_align,
  heading,
  description,
  background_media,
  enable_parallax,
}) => {
  const [animateChildren] = useInViewAnimation();
  const [inViewRef, inView] = useInView();
  const parallaxRef = useParallax(inView && enable_parallax, 0.2);

  return (
    <BackgroundGradient>
      <Container ref={inViewRef} py={["4xl", "7xl"]} textAlign={center_align ? "center" : "left"}>
        <Row justifyContent={center_align ? "center" : "flex-start"}>
          <Col ref={animateChildren} span={[12, 8, 8, 6]}>
            <RevealText as="h2" typeStyle="heading-xl">
              {heading}
            </RevealText>
            <RichText mt="sm">{description}</RichText>
          </Col>
        </Row>
        {center_align && (
          <Row mt="xl">
            <Col span={[12, 8, 6, 4]} offset={[0, 2, 3, 4]}>
              <Rule />
            </Col>
          </Row>
        )}
      </Container>
      {!!background_media && background_media?.length > 0 && (
        <Box zIndex={-1} position="absolute" height="100%" width="100%" top="0" left="0" ref={parallaxRef}>
          <ResponsiveAsset
            asset={background_media}
            imageProps={{ objectPosition: "center", objectFit: "cover" }}
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      )}
    </BackgroundGradient>
  );
};

export default FeatureBreak;
