import React, { useState, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Container, Flex } from "~/components/Layout";
import { ResponsiveImage } from "~/components/Image";
import { Text, RichText } from "~/components/Text";
import Icon from "~/components/Icon";
import { mod } from "~/helpers/mod";
import { ImageCarouselProps } from "./types";
import { ImageItem, ArrowButton } from "./styled";
import DownloadButton from "~/components/DownloadButton";

const motionConfig = {
  transition: {
    ease: "easeInOut",
  },
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  },
  initial: "hidden",
  animate: "visible",
  exit: "hidden",
};

const ImageCarousel: React.FC<ImageCarouselProps> = ({ full_bleed, items }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const counterString = `${activeItemIndex + 1} / ${items.length}`;

  const ImageContainer = full_bleed ? Box : Container;

  const { icon, heading, description } = items[activeItemIndex].caption;
  const [download] = items[activeItemIndex].caption.download || [];

  const forward = useCallback(() => setActiveItemIndex((prevItemIndex) => mod(prevItemIndex + 1, items.length)), [
    items.length,
  ]);

  const backward = useCallback(() => setActiveItemIndex((prevItemIndex) => mod(prevItemIndex - 1, items.length)), [
    items.length,
  ]);

  return (
    <Box py={["xl", "5xl"]}>
      <ImageContainer>
        <Box position="relative" overflow="hidden">
          {items.map(({ image }, responsiveImageIndex) => (
            <ImageItem
              key={responsiveImageIndex}
              $isFirst={responsiveImageIndex === 0}
              $isActive={responsiveImageIndex === activeItemIndex}
            >
              <ResponsiveImage image={image} />
            </ImageItem>
          ))}
        </Box>
      </ImageContainer>
      <Container mt={["3xl", "3xl", "3xl"]}>
        <Flex
          flexDirection={["column-reverse", "column-reverse", "row"]}
          justifyContent={["flex-start", "flex-start", "space-between"]}
        >
          <AnimatePresence exitBeforeEnter>
            <motion.div key={`${activeItemIndex}`} {...motionConfig}>
              {!!icon && <Box as="img" mb="sm" width="2.25rem" height="2.25rem" src={icon?.url} />}
              <Text as="h5" typeStyle="heading-base" textColor="primary" mb="sm">
                {heading}
              </Text>
              <RichText>{description}</RichText>
              {!!download?.file && <DownloadButton asset={download.file} label={download?.label} />}
            </motion.div>
          </AnimatePresence>
          <div>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width={["100%", "100%", "auto"]}
              color="background"
              mb={["xl", "xl", 0]}
            >
              <ArrowButton onClick={backward}>
                <Icon type="left-arrow" />
              </ArrowButton>
              <Text typeStyle="body-base" as="span" px="4xl" textColor="body">
                {counterString}
              </Text>
              <ArrowButton onClick={forward}>
                <Icon type="right-arrow" />
              </ArrowButton>
            </Flex>
          </div>
        </Flex>
      </Container>
    </Box>
  );
};

export default ImageCarousel;
