import styled, { css, keyframes } from "styled-components";
import { Box } from "~/components/Layout";
import cssSystem from "@styled-system/css";

const blink = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  74% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const LiveTextContainer = styled.div<{
  $fontFamily: string;
  $fontWeight: string;
  $fontStyle: string;
  $fontSize: number;
  $blinkCursor: boolean;
}>`
  position: relative;
  margin-top: 4rem;
  line-height: 1.08;
  letter-spacing: 0em;
  font-size: ${({ $fontSize }) => $fontSize}pt;
  min-height: ${({ $fontSize }) => $fontSize}pt;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ $fontFamily }) => $fontFamily};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-style: ${({ $fontStyle }) => $fontStyle};

  & :active,
  & :focus {
    outline: none;
    caret-color: ${({ theme }) => theme.colors.secondary};
  }

  & span.placeholder {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  & span.placeholder::after {
    content: "";
    border-right: 1px solid ${({ theme }) => theme.colors.secondary};
    display: inline;
    ${({ $blinkCursor }) =>
      $blinkCursor &&
      css`
        animation: ${blink} 1s linear infinite;
      `}
  }
  & span.editable {
    opacity: 0;
  }
`;

export const ConfigurationHeader = styled.div`
  text-transform: uppercase;
  font-size: 0.625rem;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const ConfigurationDropdown = styled(Box)`
  appearance: none;
  border-color: ${({ theme }) => theme.colors.border.primary};
  border-width: 1px;
  border-style: solid;
  padding: 0.75rem 1.5rem;
  ${cssSystem({ paddingRight: ["2.25rem", "3rem", "3rem"] })()}
  border-radius: 0;
  background: transparent;

  cursor: ${({ theme }) => theme.cursors.pointer};
  transition: border-color 0.3s ease;

  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.border.secondary};
    }
  }
`;

export const ConfigurationDropdownContainer = styled.div`
  position: relative;
  cursor: ${({ theme }) => theme.cursors.pointer};
  color: ${({ theme }) => theme.colors.primary};
`;

export const CaretContainer = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  pointer-events: none;
  ${cssSystem({ width: ["2.25rem", "3rem", "3rem"] })()}
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
