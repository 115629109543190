const WEIGHT_TO_KEY: { [key: string]: string } = {
  "100": "thin",
  "200": "extra_light",
  "300": "light",
  "400": "normal",
  "500": "medium",
  "600": "semi_bold",
  "700": "bold",
  "800": "extra_bold",
  "900": "black",
  "950": "extra_black",
};

export const fontWeightToTranslationKey = (weight: string): string => {
  return "font_weights_" + WEIGHT_TO_KEY[weight];
};
