import React from "react";
import { useInView } from "react-hook-inview";
import ReactPlayer from "react-player/lazy";
import { Container, Flex, Box } from "~/components/Layout";
import { Text } from "~/components/Text";
import { VideoEmbedSectionProps } from "./types";

const VideoEmbedSection: React.FC<VideoEmbedSectionProps> = ({
  title,
  primary_info,
  secondary_info,
  embed_link,
  video_file,
  full_bleed,
  loop_video,
  is_decorative,
}) => {
  const [inViewRef, inView] = useInView();
  const titleInfoString = `${title} • ${primary_info}`;
  const ContainerElement = full_bleed ? Box : Container;
  return (
    <Box pt="5xl" pb="7xl">
      <ContainerElement ref={inViewRef}>
        <Box position="relative" pt="47.5%" width="100%" mb="3xl">
          <Box position="absolute" top="0" left="0" width="100%" height="100%">
            <ReactPlayer
              loop={loop_video}
              muted={is_decorative}
              width="100%"
              height="100%"
              url={embed_link || video_file?.url}
              controls={!is_decorative}
              playing={is_decorative && inView}
              playsinline={is_decorative}
            />
          </Box>
        </Box>
      </ContainerElement>
      {!!(title || primary_info || secondary_info) && (
        <Container>
          <Flex
            justifyContent={["flex-start", "space-between"]}
            flexWrap="wrap"
            flexDirection={["column", "row"]}
            borderTop="1px solid"
            borderColor="utility.primary"
            pt="sm"
          >
            <Text typeStyle="heading-xs" textColor="secondary">
              {titleInfoString}
            </Text>
            <Text mt={["xs", 0]} typeStyle="body-xs" textColor="body">
              {secondary_info}
            </Text>
          </Flex>
        </Container>
      )}
    </Box>
  );
};

export default VideoEmbedSection;
