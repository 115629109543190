import styled from "styled-components";

export const Thumbnail = styled.div<{ $color?: string; $borderWidth?: string }>`
  display: flex;
  height: 3.125rem;
  width: 3.125rem;
  background: ${({ $color }) => $color};
  border-radius: 50%;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.utility.secondary};
  border-width: ${({ $borderWidth }) => $borderWidth};
`;

/* delay for transition based off of timing found in global.ts */
export const Gradient = styled.div<{
  $borderWidth?: string;
  $leftColor?: string;
  $rightColor?: string;
  $isInView?: boolean;
}>`
  border: ${({ $borderWidth }) => $borderWidth} solid;
  border-color: ${({ theme }) => theme.colors.utility.secondary};
  height: 5rem;
  margin-bottom: ${({ theme }) => theme.space.sm};
  background-size: ${({ $isInView }) => ($isInView ? "100%" : "400%")};
  background-image: linear-gradient(to right, ${({ $leftColor, $rightColor }) => $leftColor + ", " + $rightColor});
  transition: background-size 0.6s ease 1s;
`;
