import { useCallback, useState } from "react";
import { useInViewEffect } from "react-hook-inview";

/**
 * Adds a className to trigger a CSS animation when the element is in the
 * viewport. ClassName and keyframes are defined globally in `src/styles/global.ts`.
 */
export function useInViewAnimation({ threshold = 0.4, delay = 225, className = "animate-inview" } = {}): [
  (node: HTMLElement | null) => void,
  boolean,
] {
  const [inView, setInView] = useState(false);
  const observe = useInViewEffect(
    ([entry], observer) => {
      if (entry?.isIntersecting) {
        setInView(true);
        entry.target.classList.add(className);
        observer.disconnect();
      }
    },
    { threshold },
  );

  const setChildren = useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        observe(node);
        const children = node.children as HTMLCollectionOf<HTMLElement>;

        for (let i = 0; i < children.length; i += 1) {
          const child = children[i];
          child.style.opacity = "0";
          child.style.animationDelay = `${delay * i}ms`;
        }
      }
    },
    [observe, delay],
  );

  return [setChildren, inView];
}
