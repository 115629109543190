import React, { useRef, useCallback, useEffect, useState } from "react";
import { ResponsiveImage } from "~/components/Image";
import { useEventListener } from "~/helpers/hooks/useEventListener";
import { useBodyLock } from "~/helpers/hooks/useBodyLock";
import { ZoomImageProps } from "./types";
import { zoomCircleSize } from "./constants";
import { ZoomedImageContainer, ZoomedImage, ZoomCircle } from "./styled";

const ZoomImage: React.FC<ZoomImageProps> = ({ main_image: image, zoom_amount }) => {
  const [scrollLock, setScrollLock] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const lastMousePosRef = useRef({ zoomX: 0, zoomY: 0 });
  const zoomCircleRef = useRef<HTMLDivElement | null>(null);
  const zoomedImageRef = useRef<HTMLDivElement | null>(null);
  const zoomedImageSizeRef = useRef({ width: 0, height: 0 });
  const longPressTimeoutRef = useRef<number | null>(null);
  const mouseOverTimeoutRef = useRef<number | null>(null);
  const isInteractingRef = useRef(false);
  const stopInteractRef = useRef<() => void>();
  useBodyLock(scrollLock);

  /**
   * Show zoomed image and zoom circle
   */
  const startInteract = useCallback(() => {
    if (zoomedImageRef.current && zoomCircleRef.current && containerRef.current) {
      zoomedImageRef.current.style.opacity = "1";
      zoomCircleRef.current.style.opacity = "1";
      containerRef.current.style.cursor = "none";
    }
  }, []);

  /**
   * Method to update position of zoom circle.
   */
  const updateZoomedImage = useCallback(
    (zoomX?: number, zoomY?: number) => {
      if (!zoomedImageRef.current || !containerRef.current || !zoomCircleRef.current) return;

      const {
        width: containerWidth,
        height: containerHeight,
        left: containerX,
        top: containerY,
      } = containerRef.current.getBoundingClientRect();
      const { width: zoomedWidth, height: zoomedHeight } = zoomedImageSizeRef.current;

      if (!isInteractingRef.current) startInteract();

      // get/set mouse position reference for use when scrolling
      const currentZoomX = zoomX && zoomY ? zoomX : lastMousePosRef.current.zoomX;
      const currentZoomY = zoomX && zoomY ? zoomY : lastMousePosRef.current.zoomY;
      if (zoomX && zoomY) {
        lastMousePosRef.current.zoomX = zoomX;
        lastMousePosRef.current.zoomY = zoomY;
      }

      // compute new position for zoom circle
      const screenHeightDiff = (containerHeight - window.innerHeight) / 2;
      const imageWidthDiff = containerWidth - zoomedWidth;
      const imageHeightDiff = containerHeight - zoomedHeight;
      const imageX = currentZoomX - containerX;
      const imageY = currentZoomY - containerY;
      const relativeX = imageX / containerWidth;
      const relativeY = imageY / containerHeight;
      const imageOffsetX = ((relativeX - 0.5) * imageWidthDiff).toFixed(2);
      const imageOffsetY = ((relativeY - 0.5) * imageHeightDiff + containerY + screenHeightDiff).toFixed(2);

      const clipPathX = (relativeX * 100).toFixed(2);
      const clipPathY = (relativeY * 100).toFixed(2);

      // set position of circle element and image element and the image's clip path.
      zoomedImageRef.current.style.clipPath = `circle(${zoomCircleSize} at ${clipPathX}% ${clipPathY}%)`;
      zoomedImageRef.current.style.transform = `translate(calc(-50% + ${imageOffsetX}px), calc(-50% + ${imageOffsetY}px))`;
      zoomCircleRef.current.style.transform = `translate(calc(-50% + ${currentZoomX}px), calc(-50% + ${currentZoomY}px))`;
    },
    [startInteract],
  );

  const handleMouseMove = useCallback(
    (ev: MouseEvent | React.MouseEvent) => updateZoomedImage(ev.clientX, ev.clientY),
    [updateZoomedImage],
  );

  const handleTouchMove = useCallback(
    (ev: TouchEvent | React.TouchEvent): void => {
      const elementsUnderTouch = document.elementsFromPoint(ev.touches[0]?.clientX, ev.touches[0]?.clientY);
      if (elementsUnderTouch.some((element) => element.tagName === "IMG")) {
        updateZoomedImage(ev.touches[0]?.clientX, ev.touches[0]?.clientY);
      } else if (stopInteractRef.current) stopInteractRef.current();
    },
    [updateZoomedImage],
  );

  /**
   * handles the "contextmenu" event to avoid a context menu popping up during a long-press
   */
  const handleContextMenu = useCallback((ev: Event) => {
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  }, []);

  const stopInteract = useCallback(() => {
    setScrollLock(false);
    window.removeEventListener("touchmove", handleTouchMove);
    window.removeEventListener("contextmenu", handleContextMenu);
    if (longPressTimeoutRef.current) window.clearTimeout(longPressTimeoutRef.current);
    if (mouseOverTimeoutRef.current) window.clearTimeout(mouseOverTimeoutRef.current);
    if (zoomedImageRef.current && zoomCircleRef.current && containerRef.current) {
      zoomedImageRef.current.style.opacity = "0";
      zoomCircleRef.current.style.opacity = "0";
      containerRef.current.style.cursor = "default";
    }
  }, [handleContextMenu, handleTouchMove]);

  /**
   * handle touch start and set timeout to handle long-press mobile gesture
   */
  const handleTouchStart = useCallback(
    (ev: TouchEvent | React.TouchEvent) => {
      longPressTimeoutRef.current = window.setTimeout(() => {
        setScrollLock(true);
        window.addEventListener("touchmove", handleTouchMove);
        window.addEventListener("contextmenu", handleContextMenu, { passive: false });
        startInteract();
        updateZoomedImage(ev.touches[0]?.clientX, ev.touches[0]?.clientY);
      }, 300);
    },
    [handleContextMenu, handleTouchMove, startInteract, updateZoomedImage],
  );

  /**
   * resize zoomed image based on size of non-zoomed image
   */
  const handleResize = useCallback(() => {
    if (!containerRef.current || !zoomedImageRef.current || !zoomCircleRef.current) return;

    const { width, height } = containerRef.current.getBoundingClientRect();
    const zoomedWidth = width * zoom_amount;
    const zoomedHeight = height * zoom_amount;
    zoomedImageRef.current.style.width = `${zoomedWidth}px`;
    zoomedImageRef.current.style.height = `${zoomedHeight}px`;
    zoomedImageSizeRef.current = {
      width: zoomedWidth,
      height: zoomedHeight,
    };
  }, [zoom_amount]);

  useEventListener("resize", handleResize, { initial: true });

  useEffect(() => {
    stopInteractRef.current = stopInteract;
    stopInteractRef.current();
    window.addEventListener("wheel", stopInteract);
    return () => window.removeEventListener("wheel", stopInteract);
  }, [stopInteract]);

  return (
    <ZoomedImageContainer
      ref={containerRef}
      onMouseLeave={stopInteract}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={stopInteract}
      onTouchCancel={stopInteract}
    >
      <ResponsiveImage image={image} />
      <ZoomedImage aria-hidden ref={zoomedImageRef}>
        <ResponsiveImage image={image} />
      </ZoomedImage>
      <ZoomCircle ref={zoomCircleRef} />
    </ZoomedImageContainer>
  );
};

export default ZoomImage;
