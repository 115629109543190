import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import type { Asset } from "~/types/asset";

/**
 * Renders a single looping inline video from Contentstack. This is intended for
 * displaying short animations where a GIF is inappropriate. For a more robust
 * video player with support for controls, use `VideoEmbedSection`.
 */
export const Video = React.forwardRef<
  HTMLVideoElement,
  {
    video: Asset;
    className?: string;
    style?: React.CSSProperties;
  }
>(({ video, className, style = { maxWidth: "100%" } }, ref) => (
  <video ref={ref} src={video?.url} autoPlay loop muted playsInline className={className} style={style} />
));

Video.displayName = "Video";

/**
 * Renders different video sources at different breakpoints. `<video>` elements
 * don't support the `media` attribute like a `<picture>` element, so this uses
 * `matchMedia` in conjunction with the theme breakpoints to render videos in the
 * same way as ResponsiveImage and styled-system's responsive props.
 */
export const ResponsiveVideo: React.FC<{
  video: Asset[];
  className?: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
}> = ({ video, className, style = { maxWidth: "100%" }, onLoad }) => {
  const { mediaQueriesRange } = useTheme();
  const [videoIndex, setVideoIndex] = useState(0);
  const element = useRef<HTMLVideoElement>(null);
  const queriesList = useRef<MediaQueryList[]>([]);

  useEffect(() => {
    const videoElement = element.current;
    const queries = queriesList.current;

    // Ranges here need to be min-max width and dynamic, otherwise we get two
    // matchMedia listeners that both resolve to true and give undesirable results
    // (for example, @ 1024px, `(min-width: 640px)` and `(min-width: 960px)` will both fire).
    const ranges = mediaQueriesRange(video);

    function onChange(event: MediaQueryListEvent) {
      if (!videoElement) return;
      if (event.matches) {
        const index = ranges.indexOf(event.media);
        videoElement.src = video[index].url;
        setVideoIndex(index);
      }
    }

    // Initialize
    ranges.forEach((query, index) => {
      if (!videoElement) return;

      const mediaQueryList = window.matchMedia(query);

      if (mediaQueryList.matches) setVideoIndex(index);
      mediaQueryList.addEventListener("change", onChange);
      queriesList.current[index] = mediaQueryList;
    });

    // Cleanup
    return () => {
      queries.forEach((mediaQueryList) => {
        mediaQueryList.removeEventListener("change", onChange);
      });
    };
  }, [mediaQueriesRange, video]);

  useEffect(() => {
    const videoElement = element.current;
    if (videoElement) {
      videoElement.src = video[videoIndex].url;
      if (onLoad) {
        if (videoElement.readyState === 4) onLoad();
        else videoElement.oncanplay = onLoad;
      }
    }
  }, [onLoad, video, videoIndex]);

  return <Video ref={element} video={video[0]} className={className} style={style} />;
};
